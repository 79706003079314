import React from 'react';
import ShortContextIcon from '@/assets/short-context-icon.svg';
import MediumContextIcon from '@/assets/medium-ccontext-icon.svg';
import LongContextIcon from '@/assets/long-context-icon.svg';

import { pNormal, h2Style, h4Style, LinkWrapper } from '../common';

// need to change
const learMoreLink =
  'https://docs.google.com/document/d/16n6y27xrviSYVfXh9IYHwQfcWRNln28rWDGnMPwepmU/edit#heading=h.9f9tzwwrf9ri';

const IntroSection = () => {
  return (
    <>
      {/* Welcome Part */}
      <div className="bg-[#070707]">
        <section className="mx-auto max-w-[90rem] px-5 lg:px-[85px]">
          <div className="border border-gray-400/40 p-4 lg:px-4 lg:py-9">
            <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
              Welcome to the <br />
              Hallucination Index!
            </h2>
            <div className="mt-8 flex flex-col gap-8 md:flex-row">
              <div className="md:w-1/2">
                <div className="flex flex-col">
                  <p className="text-base font-normal leading-[175%] text-white">
                    The LLM landscape has changed a lot since launching our
                    first{' '}
                    <a
                      href="hallucinationindex-2023"
                      className="text-[#5C5CFF]"
                    >
                      Hallucination Index
                    </a>{' '}
                    in November 2023, with larger, more powerful open and
                    closed-sourced models being announced monthly. Since then,
                    two things happened: the term "hallucinate" became
                    Dictionary.com’s Word of the Year, and
                    Retrieval-Augmented-Generation (RAG) has become one of the
                    leading methods for building AI solutions. And while the
                    parameters and context lengths of these models continue to
                    grow, the risk of hallucinations remains.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2">
                <p className="text-base font-normal leading-[175%] text-white">
                  Our new Index evaluates how well 22 of the leading models
                  adhere to given context, helping developers make informed
                  decisions about balancing price and performance. We conducted
                  rigorous testing of top LLMs with input ranging from 1,000 to
                  100,000 tokens to answer the question of how well they perform
                  across short, medium, and long context lengths. So let's dive
                  deep into the insights. Welcome to the new Hallucination Index
                  - RAG Special!
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* About Part */}
        <div className="">
          <section className="mx-auto max-w-[90rem] px-5 lg:px-[85px]">
            <div className="border border-gray-400/40 p-4 lg:px-4 lg:py-9">
              <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
                About the Index
              </h2>
            </div>
          </section>

          <section className="mx-auto max-w-[90rem] px-5 lg:px-[85px]">
            <div className="border border-gray-400/40 p-4 lg:px-4 lg:py-9">
              <div className="flex flex-col">
                <div className="flex flex-col gap-10 md:flex-row md:justify-between lg:gap-10">
                  <div className="md:w-6/12">
                    <h4 className="mb-6 text-[28px] font-medium leading-[normal] text-white">
                      What?
                    </h4>
                    <p className="mb-6 text-base font-normal leading-[175%] text-white">
                      Adding additional context has emerged as a new way to
                      improve RAG performance and reduce reliability on vector
                      databases. So, we tested each LLM across three scenarios
                      each with varying context length.
                    </p>

                    <div className="flex items-center gap-5 border border-x-0 border-b-0 border-gray-400/40 px-4 py-8">
                      <div className="flex items-center gap-5 sm:min-w-[40%]">
                        <ShortContextIcon />
                        <p className="text-base font-normal leading-[175%] text-white">
                          Short Context
                        </p>
                      </div>
                      <p className="ml-10 text-sm font-normal leading-[175%] text-white">
                        Less than 5k tokens
                        <br />
                        equivalent to RAG on few pages
                      </p>
                    </div>
                    <div className="flex items-center gap-5 border border-x-0 border-gray-400/40 px-4 py-8">
                      <div className="flex items-center gap-5 sm:min-w-[40%]">
                        <MediumContextIcon />
                        <p className="text-base font-normal leading-[175%] text-white">
                          Medium Context
                        </p>
                      </div>
                      <p className="ml-10 text-sm font-normal leading-[175%] text-white">
                        5k to 25k tokens
                        <br />
                        equivalent to RAG on a book chapter
                      </p>
                    </div>
                    <div className="flex items-center gap-5 border border-x-0 border-t-0 border-gray-400/40 px-4 py-8">
                      <div className="flex items-center gap-5 sm:min-w-[40%]">
                        <LongContextIcon />
                        <p className="text-base font-normal leading-[175%] text-white">
                          Long Context
                        </p>
                      </div>
                      <p className="ml-10 text-sm font-normal leading-[175%] text-white">
                        40k to 100k tokens
                        <br />
                        equivalent to RAG on a book
                      </p>
                    </div>
                    <a
                      href="/hallucinationindex/methodology"
                      className="mt-8 block text-[#5C5CFF]"
                    >
                      Learn more about task type selection
                    </a>
                  </div>
                  <div className="md:w-5/12">
                    <h4 className="mb-6 text-[28px] font-medium leading-[normal] text-white">
                      How?
                    </h4>
                    <p className="mb-6 text-base font-normal leading-[175%] text-white">
                      We followed the following steps when testing each LLM:
                    </p>
                    <p className="mb-6 text-base font-normal leading-[175%] text-white">
                      1. We gathered diverse datasets reflecting real-world
                      scenarios across three different context lengths.
                    </p>
                    <p className="mb-6 text-base font-normal leading-[175%] text-white">
                      2. We employed a high-performance evaluation model, called
                      Context Adherence, to measure factual accuracy and
                      closed-domain hallucinations - cases where the model said
                      things that were not provided in the context data.{' '}
                    </p>
                    <p className="mb-6 text-base font-normal leading-[175%] text-white">
                      Learn more about{' '}
                      <a
                        href="https://docs.rungalileo.io/galileo/gen-ai-studio-products/guardrail-store/context-adherence/groundedness"
                        target="_blank"
                        className="my-8 text-[#5C5CFF]"
                      >
                        Context Adherence
                      </a>{' '}
                      evaluation metric and the{' '}
                      <a
                        href="https://www.rungalileo.io/blog/chainpoll"
                        className="my-8 text-[#5C5CFF]"
                      >
                        ChainPoll
                      </a>{' '}
                      evaluation method.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mx-auto max-w-[90rem] px-5 lg:px-[85px]">
            <div className="flex flex-col gap-12 border border-gray-400/40 p-4 md:flex-row md:items-center md:gap-2 lg:px-4 lg:py-9">
              <div className="flex gap-2 md:w-4/12 md:items-center">
                <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
                  10
                </h2>
                <p className="text-[22px] font-bold text-white md:text-[32px] md:leading-9">
                  closed-source
                  <br />
                  models
                </p>
              </div>
              <div className="flex gap-2 md:w-4/12 md:items-center">
                <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
                  12
                </h2>
                <p className="text-[22px] font-bold text-white md:text-[32px] md:leading-9">
                  open-source
                  <br />
                  models
                </p>
              </div>
              <div className="flex gap-2 md:w-4/12 md:items-center">
                <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
                  3
                </h2>
                <p className="text-[22px] font-bold text-white md:text-[32px] md:leading-9">
                  RAG
                  <br />
                  tasks
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default IntroSection;
