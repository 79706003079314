import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

import { h2Style, pNormal, h5Style } from '../../common';

import AnthropicLogo from '@/assets/anthropic-w.svg';
import GoogleLogo from '@/assets/google.svg';
import OpenAi from '@/assets/openai.svg';
import QwenLogo from '@/assets/qwen.svg';
import MetaLogo from '@/assets/meta.svg';
import TopModelSection from './top-model-section';

const overallWinners = [
  {
    label: 'Best performing model',
    icon: <AnthropicLogo className="w-16 min-w-16" />,
    text: 'Claude 3.5 Sonnet due to great performance on all tasks with context support up to 200k.',
    link: '/hallucinationindex/claude-3-5-sonnet-20240620',
  },
  {
    label: 'Best performance for the cost',
    icon: <OpenAi className="w-16 min-w-16 [&>*:first-child]:fill-white" />,
    text: 'GPT-4o-mini due to near flawless performance for all tasks at affordable price.',
    link: '/hallucinationindex/gpt-4o-mini-2024-07-18',
  },
  {
    label: 'Best performing open-source model',
    icon: <QwenLogo className="w-16 min-w-16" />,
    text: 'Qwen2-72B-Instruct due to great performance in SCR and MCR with context support up to 128k.',
    link: '/hallucinationindex/qwen2-72b-instruct',
  },
];

const shortContext = [
  {
    label: 'Best closed source model',
    icon: <AnthropicLogo className="w-16 min-w-16" />,
    text: 'claude-3-5-sonnet',
    value: '0.97',
    link: '/hallucinationindex/claude-3-5-sonnet-20240620',
  },
  {
    label: 'Best open source model',
    icon: <MetaLogo className="w-16 min-w-16" />,
    text: 'llama-3.1-405b-instruct',
    value: '0.97',
    link: '/hallucinationindex/meta-llama-31-405b-instruct',
  },
  {
    label: 'Best affordable model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'gemini-1.5-flash',
    value: '0.94',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
];

const mediumContext = [
  {
    label: 'Best closed source model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'gemini-1.5-flash',
    value: '1.00',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
  {
    label: 'Best open source model',
    icon: <MetaLogo className="w-16 min-w-16" />,
    text: 'llama-3.1-8b-instruct',
    value: '1.00',
    link: '/hallucinationindex/meta-llama-31-8b-instruct',
  },
  {
    label: 'Best affordable model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'gemini-1.5-flash',
    value: '1.00',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
];

const longContext = [
  {
    label: 'Best closed source model',
    icon: <OpenAi className="w-16 min-w-16 [&>*:first-child]:fill-white" />,
    text: 'gpt-4o-mini',
    value: '1.0',
    link: '/hallucinationindex/gpt-4o-mini-2024-07-18',
  },
  {
    label: 'Best affordable model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'gemini-1.5-flash',
    value: '0.92',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
];

const TopModels = () => {
  const cellPadding = 'p-5';
  return (
    <div className="bg-[#070707]">
      <section className="mx-auto flex max-w-[90rem] flex-col px-5 lg:px-[85px]">
        <div className="flex items-center gap-2 border border-t-0 border-gray-400/40 p-4 lg:px-4 lg:py-9">
          <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
            Top Models for RAG Applications
          </h2>
        </div>

        <div className="flex flex-col">
          <div className="border-x border-gray-400/40 p-4 pb-8 pt-0 lg:px-4">
            <div className="flex flex-col gap-6 md:flex-row">
              <div
                className={`${h5Style} pt-10 !font-inter !text-white md:w-1/3`}
              >
                Overall Winners for RAG
              </div>

              <div className="flex w-full flex-col md:w-2/3">
                {overallWinners.map((item) => (
                  <a
                    href={item.link}
                    key={item.label}
                    className="gradient-to-b flex flex-wrap items-center justify-between gap-4 border-b border-gray-400/40 from-[#F5F5FA] to-[#DCDCE0] py-8 last:border-none md:flex-nowrap"
                  >
                    <div className="shrink-0 grow-0 sm:w-1/3">
                      <p
                        className={`${pNormal} w-full flex-grow-0 !font-medium !text-white opacity-80`}
                      >
                        {item.label}
                      </p>
                    </div>

                    <div className="flex w-[calc(100%_-_68px)] max-w-[400px] grow-0 flex-row items-center gap-4 md:ml-auto md:w-full">
                      {item.icon}
                      <p className={`${pNormal} font-medium !text-white`}>
                        {item.text}
                      </p>
                    </div>

                    <div className="w-12 sm:w-16">
                      <FiChevronRight className="h-8 w-8 !text-white" />
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <TopModelSection
        categoryTitle="Short Context RAG"
        categorySubtitle="(&lt;5k tokens)"
        contextList={shortContext}
        showHeader
      />
      <TopModelSection
        categoryTitle="Medium Context RAG"
        categorySubtitle="(5k to 25k tokens)"
        contextList={mediumContext}
      />
      <TopModelSection
        categoryTitle="Long Context RAG"
        categorySubtitle="(40k to 100k tokens)"
        contextList={longContext}
      />
    </div>
  );
};

export default TopModels;
