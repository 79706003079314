import React from 'react';

import { h3Style, pNormal } from '@/components/hallucination-index-2024/common';
import Chart from '../common/chart';
import Heatmap from '../common/heatmap';
import ResultSnapshot from '../common/result-snapshot';
import RagTaskExamples from '../common/rag-task-examples';
import {
  mcrSimpleHeatmapNames,
  IMcrLcrModel,
} from '@/components/hallucination-index-2024/model-data-helpers';

import AnthropicLogo from '@/assets/anthropic.svg';
import GoogleLogo from '@/assets/google.svg';
import PricingInfo from '../common/pricing-info';
import { RagTaskExampleData } from '../rag-task-examples-data';
import MetaLogo from '@/assets/meta.svg';
import DatabricksLogo from '@/assets/databricks.svg';

const resultSnapshotItems = [
  {
    category: 'Best closed-source model',
    icon: <GoogleLogo />,
    modelName: `gemini-1.5-flash`,
    score: 1.0,
  },
  {
    category: 'Lagging closed-source model',
    icon: <AnthropicLogo />,
    modelName: `claude-3-haiku`,
    score: 0.96,
  },
  {
    category: 'Best open-source model',
    icon: <MetaLogo />,
    modelName: `llama-3.1-8b-instruct`,
    score: 1.0,
  },
  {
    category: 'Lagging open-source model',
    icon: <DatabricksLogo className="ml-[8px]" />,
    modelName: `dbrx-instruct`,
    score: 0.95,
  },
  {
    category: 'Best performance for the cost',
    icon: <GoogleLogo />,
    modelName: `gemini-1.5-flash`,
    score: 1.0,
  },
];

const defaultSelectedChartFilters = [
  {
    name: 'promptType',
    values: ['Simple'],
  },
];

type Props = {
  mcrModels: IMcrLcrModel[];
  ragTaskExamples: RagTaskExampleData[];
};

const MCR = ({ mcrModels, ragTaskExamples }: Props) => {
  const heatmapData = {
    type: 'mcr' as const,
    models: mcrModels
      .filter((m) => mcrSimpleHeatmapNames[m.name])
      .map((m) => m.name),
    title: 'Recall heatmap',
    description: `This heatmap shows the model's ability to recall information in different parts of the context. The x-axis represents the length of the context during the experiment, and the y-axis represents the location of the information. Green indicates successful recall, while red indicates failure.`,
  };
  return (
    <section className="my-12 lg:my-24">
      {/* Head */}
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="md:w-1/2">
          <h3 className={h3Style}>Medium Context RAG (MCR)</h3>
          <p className="my-3 text-lg leading-8 !text-black lg:text-[22px]">
            5k to 25k tokens
          </p>
        </div>

        <p className={`${pNormal} !text-[#454547] md:w-1/2`}>
          The Medium Context RAG aims to determine the most effective model for
          comprehending long contexts spanning from 5k to 25k tokens. It focuses
          on identifying any loss of information and reasoning ability within
          these extensive contexts. Additionally, we experiment with a prompting
          technique known as Chain-of-Note to improve performance as it has
          worked for short context. This task is akin to doing RAG on a few book
          chapters.
        </p>
      </div>

      {/* Chart */}
      <div className="my-16">
        <Chart
          maxYTickOffset={0.1}
          minYTickOffset={-0.04}
          models={mcrModels}
          enabledFilters={[
            'type',
            'contextLength',
            'promptType',
            'pricePerToken',
            'responseCost',
          ]}
          defaultSelectedChartFilters={defaultSelectedChartFilters}
        />
        <PricingInfo />
      </div>
      {/* Result Snapshot */}
      <ResultSnapshot items={resultSnapshotItems} />
      {/* Heatmap */}
      <Heatmap {...heatmapData} />
      {/* RAG Task Examples */}
      <RagTaskExamples items={ragTaskExamples} />
    </section>
  );
};

export default MCR;
