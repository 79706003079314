import React from 'react';

import { h3Style } from '../common';
import RadarChart from './radar-chart';
import type { IRadarChartModel } from '../model-data-helpers';

type Props = {
  models: IRadarChartModel[];
};

const PerformanceDataset = ({ models }) => {
  return (
    <section className="mx-auto w-full max-w-[90rem] lg:px-[85px]">
      <div className="flex w-full flex-col gap-6 border border-t-0 border-black/20 px-4 py-10 md:flex-row md:px-6">
        <div className="px-4 md:w-2/5 lg:pr-10">
          <h3 className={`${h3Style} mb-3`}>Performance on datasets</h3>
          <p className="text-lg text-black lg:text-[22px] lg:leading-8">
            Here is the performance of models on each dataset. The datasets are
            selected to test for different capabilities ranging from robustness
            to noise to the ability to do math.
          </p>
        </div>

        <div className="md:w-3/5">
          <RadarChart models={models} />
        </div>
      </div>
    </section>
  );
};

export default PerformanceDataset;
