import React from 'react';
import { h4Style } from '@/components/hallucination-index-2024/common';
import Dropdown from '../../dropdown';
import { LinkWrapper } from '@/components/hallucination-index-2024/common';

type Props = {
  models: string[];
  title: string;
  description: string;
  type: 'mcr' | 'lcr';
};

const Heatmap = ({ models, title, description, type }: Props) => {
  const firstHeatmap = models[0];
  const [currentModel, setCurrentModel] = React.useState(firstHeatmap);

  const dropdownItems = models.map((model) => ({
    label: model,
    value: model,
  }));
  const url = `${currentModel.toLocaleLowerCase().replace('.', '')}`;

  return (
    <section className="my-6 w-full lg:my-10">
      {/* Heading */}
      <div className="mb-6 lg:mb-10">
        <h4 className={`${h4Style} mb-3`}>{title}</h4>
        <p className="max-w-[775px] text-base font-normal leading-7 text-black">
          {description}
        </p>
      </div>

      <div className="mt-4 flex flex-col items-center gap-4 sm:flex-row">
        <Dropdown
          items={dropdownItems}
          onChange={(v) => {
            setCurrentModel(v);
          }}
          defaultSelected={0}
          value={dropdownItems.find((item) => item.value === currentModel)}
        />
      </div>

      <p className="mt-4 text-xl leading-7 text-black lg:text-[22px] lg:leading-9">
        Heatmap for{' '}
        <LinkWrapper
          className="capitalize text-hi-100 hover:opacity-80"
          label={currentModel}
          type="internalLink"
          url={url}
        />
      </p>

      <div className="w-full">
        <img
          alt={currentModel}
          src={`/hi/${type}-simple/${currentModel}.png`}
          className="w-full"
        />
      </div>
    </section>
  );
};

export default Heatmap;
