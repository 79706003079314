import React from 'react';

import { h3Style, pNormal } from '@/components/hallucination-index-2024/common';
import {
  IMcrLcrModel,
  lcrSimpleHeatmapNames,
} from '@/components/hallucination-index-2024/model-data-helpers';
import Chart from '../common/chart';
import Heatmap from '../common/heatmap';
import ResultSnapshot from '../common/result-snapshot';
import RagTaskExamples from '../common/rag-task-examples';
import AnthropicLogo from '@/assets/anthropic.svg';
import GoogleLogo from '@/assets/google.svg';
import { RagTaskExampleData } from '../rag-task-examples-data';
import OpenAiLogo from '@/assets/openai.svg';

const defaultSelectedChartFilters = [
  {
    name: 'promptType',
    values: ['Simple'],
  },
];

const resultSnapshotItems = [
  {
    category: 'Best closed-source model',
    icon: <OpenAiLogo />,
    modelName: `gpt-4o-mini`,
    score: 1.0,
  },
  {
    category: 'Lagging closed-source model',
    icon: <AnthropicLogo />,
    modelName: `claude-3-haiku`,
    score: 0.7,
  },
  {
    category: 'Best performance for the cost',
    icon: <OpenAiLogo />,
    modelName: `gpt-4o-mini`,
    score: 1,
  },
];

type Props = {
  lcrModels: IMcrLcrModel[];
  ragTaskExamples: RagTaskExampleData[];
};

const LCR = ({ lcrModels, ragTaskExamples }: Props) => {
  const models = lcrModels
    .map((m) => m.name)
    .filter((name) => lcrSimpleHeatmapNames[name]);

  const heatmapData = {
    type: 'lcr' as const,
    models,

    title: 'Recall heatmap',
    description: `As the name suggests, this heatmap indicates the model's success in recalling information at different locations in the context. Green signifies success, while red indicates failure.`,
  };
  return (
    <section className="mt-12">
      {/* Head */}
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="md:w-1/2">
          <h3 className={h3Style}>Long Context RAG (LCR)</h3>
          <p className="my-3 text-lg leading-8 !text-black lg:text-[22px]">
            40k to 100k tokens
          </p>
        </div>

        <p className={`${pNormal} !text-[#454547] md:w-1/2`}>
          This heatmap shows the model's ability to recall information in
          different parts of the context. The x-axis represents the length of
          the context during the experiment, and the y-axis represents the
          location of the information. Green indicates successful recall, while
          red indicates failure.
        </p>
      </div>

      {/* Chart */}
      <div className="my-16">
        <Chart
          maxYTickOffset={0.05}
          minYTickOffset={-0.1}
          models={lcrModels}
          enabledFilters={[
            'type',
            'contextLength',
            'promptType',
            'pricePerToken',
            'responseCost',
          ]}
          defaultSelectedChartFilters={defaultSelectedChartFilters}
        />
      </div>
      {/* Result Snapshot */}
      <ResultSnapshot items={resultSnapshotItems} />
      {/* Heatmap */}
      <Heatmap {...heatmapData} />
      {/* RAG Task Examples */}
      <RagTaskExamples items={ragTaskExamples} />
    </section>
  );
};

export default LCR;
