// TooltipComponent.tsx
import React, { ComponentProps } from 'react';
import { Tooltip as RTooltip } from 'react-tooltip';

type TooltipProps = ComponentProps<typeof RTooltip>;
interface TooltipComponentProps {
  id: string;
  content: React.ReactNode;
  children: React.ReactNode;
  tooltipProps?: TooltipProps;
}

const AppTooltip: React.FC<TooltipComponentProps> = ({
  id,
  content,
  children,
  tooltipProps,
}) => {
  return (
    <>
      <a data-tooltip-id={id} className={`cursor-pointer`}>
        {children}
      </a>
      <RTooltip
        className="app-tooltip"
        classNameArrow="app-tooltip-arrow"
        id={id}
        {...tooltipProps}
      >
        {content}
      </RTooltip>
    </>
  );
};

AppTooltip.defaultProps = {
  tooltipProps: {},
};

export default AppTooltip;
