type Prompt = {
  context: string;
  queston: string;
  task: string;
  shortContext: string;
};

const longPrompt: Prompt = {
  context: `
    Documents:

\-- DOCUMENT 0 --

We specialize in markets where our computing platforms can provide tremendous acceleration for applications. These platforms incorporate processors, interconnects, software, algorithms, systems, and services to deliver unique value. Our platforms address four large markets where our expertise is critical: Data Center, Gaming, Professional Visualization, and Automotive. The NVIDIA Data Center platform is focused on accelerating the most compute-intensive workloads, such as AI, data analytics, graphics and scientific computing, delivering significantly better performance and power efficiency relative to conventional CPU-only approaches. It is deployed in cloud, hyperscale, on-premises and edge data centers. The platform consists of compute and networking offerings typically delivered to customers as systems, subsystems, or modules, along with software and services. Our networking offerings include end-to-end platforms for InfiniBand and Ethernet, consisting of network adapters, cables, DPUs, and switch systems, as well as a full software stack. This has enabled us to architect data center-scale computing platforms that can interconnect thousands of compute nodes with high- performance networking. While historically the server was the unit of computing, as AI and HPC workloads have become extremely large spanning thousands of compute nodes, the data center has become the new unit of computing, with networking

\-- DOCUMENT 1 --

as an integral part. Our end customers include the world’s leading public cloud and consumer internet companies, thousands of enterprises and startups, and public sector entities. We work with industry leaders to help build or transform their applications and data center infrastructure. Our direct customers include original equipment manufacturers, or OEMs, original device manufacturers, or ODMs, system integrators and distributors which we partner with to help bring our products to market. We also have partnerships in automotive, healthcare, financial services, manufacturing, and retail among others, to accelerate the adoption of AI. At the foundation of the NVIDIA accelerated computing platform are our GPUs, which excel at parallel workloads such as the training and inferencing of neural networks. They are available in the NVIDIA accelerated computing platform and in industry standard servers from every major cloud provider and server maker. Beyond GPUs, our data center platform expanded to include DPUs in fiscal year 2022 and CPUs in fiscal year 2024. We can optimize across the entire computing, networking and storage stack to deliver data center-scale computing solutions. Our gaming platforms leverage our GPUs and sophisticated software to enhance the gaming experience with smoother, higher quality graphics. We developed NVIDIA RTX to bring next generation graphics and AI to games. NVIDIA RT

\-- DOCUMENT 2 --

X features ray tracing technology for real-time, cinematic-quality rendering. Ray tracing, which has long been used for special effects in the movie industry, is a computationally intensive technique that simulates the physical behavior of light to achieve greater realism in computer-generated scenes. NVIDIA RTX also features deep learning super sampling, or NVIDIA DLSS, our AI technology that boosts frame rates while generating beautiful, sharp images for games. RTX GPUs will also accelerate a new generation of AI applications. With an installed base of over 100 million AI capable PCs, more than 500 RTX AI-enabled applications and games, and a robust suite of development tools, RTX is already the AI PC leader. We serve the Professional Visualization market by working closely with independent software vendors, or ISVs, to optimize their offerings for NVIDIA GPUs. Our GPU computing platform enhances productivity and introduces new capabilities for critical workflows in many fields, such as design and manufacturing and digital content creation. Design and manufacturing encompass computer-aided design, architectural design, consumer-products manufacturing, medical instrumentation, and aerospace. Digital content creation includes professional video editing and post-production, special effects for films, and broadcast- television graphics. Automotive market is comprised of platform solutions for automated driving and in-vehicle

\-- DOCUMENT 3 --

cockpit computing. Leveraging our technology leadership in AI and building on our long-standing automotive relationships, we are delivering a complete end-to-end solution for the AV market under the DRIVE Hyperion brand. We have demonstrated multiple applications of AI within the car: AI can drive the car itself as a pilot in fully autonomous mode or it can also be a co-pilot, assisting the human driver while creating a safer driving experience. We are working with several hundred partners in the automotive ecosystem including automakers, truck makers, tier-one suppliers, sensor manufacturers, automotive research institutions, HD mapping companies, and startups to develop and deploy AI systems for self-driving vehicles. Our unified AI computing architecture starts with training deep neural networks using our Data Center computing solutions, and then running a full perception, fusion, planning, and control stack within the vehicle on the NVIDIA DRIVE Hyperion platform. DRIVE Hyperion consists of the high-performance, energy efficient DRIVE AGX computing hardware, a reference sensor set that supports full self-driving capability as well as an open, modular DRIVE software platform for autonomous driving, mapping, and parking services, and intelligent in-vehicle experiences. Advancing the NVIDIA accelerated computing platform. Our accelerated computing platform can solve complex problems in significantly less time and with lower

\-- DOCUMENT 4 --

power consumption than alternative computational approaches. Indeed, it can help solve problems that were previously deemed unsolvable. We work to deliver continued performance leaps that outpace Moore’s Law by leveraging innovation across the architecture, chip design, system, interconnect, and software layers. This full-stack innovation approach allows us to deliver order-of-magnitude performance advantages relative to legacy approaches in our target markets, which include Data Center, Gaming, Professional Visualization, and Automotive. While the computing requirements of these end markets are diverse, we address them with a unified underlying architecture leveraging our GPUs, CUDA and networking technologies as the fundamental building blocks. The programmable nature of our architecture allows us to make leveraged investments in research and development: we can support several multi-billion-dollar end markets with shared underlying technology by using a variety of software stacks developed either internally or by third-party developers and partners. We utilize this platform approach in each of our target markets. Extending our technology and platform leadership in AI. We provide a complete, end-to-end accelerated computing platform for AI, addressing both training and inferencing. This includes full-stack data center-scale compute and networking solutions across processing units, interconnects, systems, and software. Our compute solutions include all three major processing units in

\-- DOCUMENT 5 --

AI servers – GPUs, CPUs, and DPUs. GPUs are uniquely suited to AI, and we will continue to add AI-specific features to our GPU architecture to further extend our leadership position. In addition, we offer DGX Cloud, an AI-training-as-a-service platform, and NeMo – a complete solution for building enterprise-ready Large Language Models, or LLMs, using open source and proprietary LLMs created by NVIDIA and third parties. Our AI technology leadership is reinforced by our large and expanding ecosystem in a virtuous cycle. Our computing platforms are available from virtually every major server maker and CSP, as well as on our own AI supercomputers. There are over 4.7 million developers worldwide using CUDA and our other software tools to help deploy our technology in our target markets. We evangelize AI through partnerships with hundreds of universities and thousands of startups through our Inception program. Additionally, our Deep Learning Institute provides instruction on the latest techniques on how to design, train, and deploy neural networks in applications using our accelerated computing platform. Extending our technology and platform leadership in computer graphics. We believe that computer graphics infused with AI is fundamental to the continued expansion and evolution of computing. We apply our research and development resources to enhance

\-- DOCUMENT 6 --

the user experience for consumer entertainment and professional visualization applications and create new virtual world and simulation capabilities. Our technologies are instrumental in driving the gaming, design, and creative industries forward, as developers leverage our libraries and algorithms to deliver an optimized experience on our GeForce and NVIDIA RTX platforms. Our computer graphics platforms leverage AI end-to-end, from the developer tools and cloud services to the Tensor Cores included in all RTX-class GPUs. For example, NVIDIA Avatar Cloud Engine, or ACE, is a suite of technologies that help developers bring digital avatars to life with generative AI, running in the cloud or locally on the PC. GeForce Experience enhances each gamer’s experience by optimizing their PC’s settings, as well as enabling the recording and sharing of gameplay. Our Studio drivers enhance and accelerate a number of popular creative applications. Omniverse is real-time 3D design collaboration and virtual world simulation software that empowers artists, designers, and creators to connect and collaborate in leading design applications. We also enable interactive graphics applications - such as games, movie and photo editing and design software - to be accessed by almost any device, almost anywhere, through our cloud platforms such as vGPU for enterprise and GeForce NOW for gaming. Advancing the leading autonomous vehicle platform

\-- DOCUMENT 7 --

. We believe the advent of autonomous vehicles, or AV, and electric vehicles, or EV, is revolutionizing the transportation industry. The algorithms required for autonomous driving - such as perception, localization, and planning - are too complex for legacy hand- coded approaches and will use multiple neural networks instead. In addition, EV makers are looking for next-generation centralized car computers that integrate a wide range of intelligent functions into a single AI compute platform. Therefore, we provide an AI-based hardware and software solution, designed and implemented from the ground up based on automotive safety standards, for the AV and EV market under the DRIVE brand, which we are bringing to market through our partnerships with automotive OEMs, tier-1 suppliers, and start-ups. Our AV solution also includes the GPU-based hardware required to train the neural networks before their in-vehicle deployment, as well as to re-simulate their operation prior to any over-the-air software updates. We believe our comprehensive, top-to-bottom and end-to-end approach will enable the transportation industry to solve the complex problems arising from the shift to autonomous driving. Leveraging our intellectual property, or IP. We believe our IP is a valuable asset that can be accessed by our customers and partners through license and development agreements when

\-- DOCUMENT 8 --

they desire to build such capabilities directly into their own products or have us do so through a custom development. Such license and development arrangements can further enhance the reach of our technology. Our worldwide sales and marketing strategy is key to achieving our objective of providing markets with our high-performance and efficient computing platforms and software. Our sales and marketing teams, located across our global markets, work closely with end customers and various industry ecosystems through our partner network. Our partner network incorporates global, regional and specialized CSPs, OEMs, ODMs, system integrators, independent software vendors, or ISVs, add-in board manufacturers, or AIBs, distributors, automotive manufacturers and tier-1 automotive suppliers, and other ecosystem participants. Members of our sales team have technical expertise and product and industry knowledge. We also employ a team of application engineers and solution architects to provide pre-sales assistance to our partner network in designing, testing, and qualifying system designs that incorporate our platforms. For example, our solution architects work with CSPs to provide pre-sales assistance to optimize their hardware and software infrastructure for generative AI and LLM training and deployment. They also work with foundation model and enterprise software developers to optimize the training and fine-tuning of their models and services, and

\-- DOCUMENT 9 --

with enterprise end-users, often in collaboration with their global system integrator of choice, to fine-tune models and build AI applications. We believe that the depth and quality of our design support are key to improving our partner network’s time-to-market, maintaining a high level of customer satisfaction, and fostering relationships that encourage our end customers and partner network to use the next generation of our products within each platform. To encourage the development of applications optimized for our platforms and software, we seek to establish and maintain strong relationships in the software development community. Engineering and marketing personnel engage with key software developers to promote and discuss our platforms, as well as to ascertain individual product requirements and solve technical problems. Our developer program supports the development of AI frameworks, SDKs, and APIs for software applications and game titles that are optimized for our platforms. Our Deep Learning Institute provides in-person and online training for developers in industries and organizations around the world to build AI and accelerated computing applications that leverage our platforms. Seasonality Our computing platforms serve a diverse set of markets such as data centers, gaming, professional visualization, and automotive. Our desktop gaming products typically see stronger revenue in the second half of our fiscal year. Historical seasonality trends may not repeat. We utilize a fable

\-- DOCUMENT 10 --

ss and contracting manufacturing strategy, whereby we employ and partner with key suppliers for all phases of the manufacturing process, including wafer fabrication, assembly, testing, and packaging. We use the expertise of industry-leading suppliers that are certified by the International Organization for Standardization in such areas as fabrication, assembly, quality control and assurance, reliability, and testing. Additionally, we can avoid many of the significant costs and risks associated with owning and operating manufacturing operations. While we may directly procure certain raw materials used in the production of our products, such as memory, substrates, and a variety of components, our suppliers are responsible for procurement of most raw materials used in the production of our products. As a result, we can focus our resources on product design, quality assurance, marketing, and customer support. In periods of growth, we may place non-cancellable inventory orders for certain product components in advance of our historical lead times, pay premiums, or provide deposits to secure future supply and capacity and may need to continue to do so. We have expanded our supplier relationships to build redundancy and resilience in our operations to provide long-term manufacturing capacity aligned with growing customer demand. Our supply chain is concentrated in the Asia-Pacific region. We utilize foundries, such as Taiwan

\-- DOCUMENT 11 --

Semiconductor Manufacturing Company Limited, or TSMC, and Samsung Electronics Co., Ltd., or Samsung, to produce our semiconductor wafers. We purchase memory from Micron Technology, Inc., SK Hynix Inc., and Samsung. We utilize CoWoS technology for semiconductor packaging. We engage with independent subcontractors and contract manufacturers such as Hon Hai Precision Industry Co., Ltd., Wistron Corporation, and Fabrinet to perform assembly, testing and packaging of our final products. The market for our products is intensely competitive and is characterized by rapid technological change and evolving industry standards. We believe that the principal competitive factors in this market are performance, breadth of product offerings, access to customers and partners and distribution channels, software support, conformity to industry standard APIs, manufacturing capabilities, processor pricing, and total system costs. We believe that our ability to remain competitive will depend on how well we are able to anticipate the features and functions that customers and partners will demand and whether we are able to deliver consistent volumes of our products at acceptable levels of quality and at competitive prices. We expect competition to increase from both existing competitors and new market entrants with products that may be lower priced than ours or may provide better performance or additional features not provided by our products

\-- DOCUMENT 12 --

. In addition, it is possible that new competitors or alliances among competitors could emerge and acquire significant market share. A significant source of competition comes from companies that provide or intend to provide GPUs, CPUs, DPUs, embedded SoCs, and other accelerated, AI computing processor products, and providers of semiconductor-based high-performance interconnect products based on InfiniBand, Ethernet, Fibre Channel, and proprietary technologies. Some of our competitors may have greater marketing, financial, distribution and manufacturing resources than we do and may be more able to adapt to customers or technological changes. We expect an increasingly competitive environment in the future. We rely primarily on a combination of patents, trademarks, trade secrets, employee and third-party nondisclosure agreements, and licensing arrangements to protect our IP in the United States and internationally. Our currently issued patents have expiration dates from February 2024 to August 2043. We have numerous patents issued, allowed, and pending in the United States and in foreign jurisdictions. Our patents and pending patent applications primarily relate to our products and the technology used in connection with our products. We also rely on international treaties, organizations, and foreign laws to protect our IP. The laws of certain foreign countries in which our products are or may be

\-- DOCUMENT 13 --

manufactured or sold, including various countries in Asia, may not protect our products or IP rights to the same extent as the laws of the United States. This decreased protection makes the possibility of piracy of our technology and products more likely. We continuously assess whether and where to seek formal protection for innovations and technologies based on such factors as: In October 2023, the USG announced new and updated licensing requirements that became effective in our fourth quarter of fiscal year 2024 for exports to China and Country Groups D1, D4, and D5 (including but not limited to Saudi Arabia, the United Arab Emirates, and Vietnam, but excluding Israel) of our products exceeding certain performance thresholds, including A100, A800, H100, H800, L4, L40, L40S and RTX 4090. The licensing requirements also apply to the export of products exceeding certain performance thresholds to a party headquartered in, or with an ultimate parent headquartered in, Country Group D5, including China. On October 23, 2023, the USG informed us the licensing requirements were effective immediately for shipments of our A100, A800, H100, H800, and L40S products. Our competitive position has

\-- DOCUMENT 14 --

been harmed, and our competitive position and future results may be further harmed in the long term, if there are further changes in the USG’s export controls. Given the increasing strategic importance of AI and rising geopolitical tensions, the USG has changed and may again change the export control rules at any time and further subject a wider range of our products to export restrictions and licensing requirements, negatively impacting our business and financial results. In the event of such change, we may be unable to sell our inventory of such products and may be unable to develop replacement products not subject to the licensing requirements, effectively excluding us from all or part of the China market, as well as other impacted markets, including the Middle East. While we work to enhance the resiliency and redundancy of our supply chain, which is currently concentrated in the Asia-Pacific region, new and existing export controls or changes to existing export controls could limit alternative manufacturing locations and negatively impact our business. Refer to “Item 1A. Risk Factors – Risks Related to Regulatory, Legal, Our Stock and Other Matters” for a discussion of this potential impact. Compliance with laws, rules, and regulations has not otherwise had a material effect upon our capital expenditures, results of operations, or competitive position and

\-- DOCUMENT 15 --

we do not currently anticipate material capital expenditures for environmental control facilities. Compliance with existing or future governmental regulations, including, but not limited to, those pertaining to IP ownership and infringement, taxes, import and export requirements and tariffs, anti-corruption, business acquisitions, foreign exchange controls and cash repatriation restrictions, data privacy requirements, competition and antitrust, advertising, employment, product regulations, cybersecurity, environmental, health and safety requirements, the responsible use of AI, climate change, cryptocurrency, and consumer laws, could increase our costs, impact our competitive position, and otherwise may have a material adverse impact on our business, financial condition and results of operations in subsequent periods. Refer to “Item 1A. Risk Factors” for a discussion of these potential impacts. Sustainability and Governance NVIDIA invents computing technologies that improve lives and address global challenges. Our goal is to integrate sound environmental, social, and corporate governance principles and practices into every aspect of the Company. The Nominating and Corporate Governance Committee of our Board of Directors is responsible for reviewing and discussing with management our practices related to sustainability and corporate governance. We assess our programs annually in consideration of stakeholder expectations, market trends, and business risks and opportunities. These issues are important for our continued

\-- DOCUMENT 16 --

business success and reflect the topics of highest concern to NVIDIA and our stakeholders. The following section and the Human Capital Management Section below provide an overview of our principles and practices. More information can be found on our website and in our annual Sustainability Report. Information contained on our website or in our annual Sustainability Report is not incorporated by reference into this or any other report we file with the Securities and Exchange Commission, or the SEC. Refer to “Item 1A. Risk Factors” for a discussion of risks and uncertainties we face related to sustainability. In May 2023, we published metrics related to our environmental impact for fiscal year 2023. Fiscal year 2024 metrics are expected to be published in the first half of fiscal year 2025. There has been no material impact to our capital expenditures, results of operations or competitive position associated with global environmental sustainability regulations, compliance, or costs from sourcing renewable energy. By the end of fiscal year 2025, our goal is to purchase or generate enough renewable energy to match 100% of our global electricity usage for our offices and data centers. In fiscal year 2023, we increased the percentage of our total electricity use matched by renewable energy purchases to 44%. By fiscal year

\-- DOCUMENT 17 --

2026, we aim to engage manufacturing suppliers comprising at least 67% of NVIDIA’s scope 3 category 1 GHG emissions with goal of effecting supplier adoption of science-based targets. Whether it is creation of technology to power next-generation laptops or designs to support high-performance supercomputers, improving energy efficiency is important in our research, development, and design processes. GPU-accelerated computing is inherently more energy efficient than traditional computing for many workloads because it is optimized for throughput, performance per watt, and certain AI workloads. The energy efficiency of our products is evidenced by our continued strong presence on the Green500 list of the most energy-efficient systems. We powered 24 of the top 30 most energy efficient systems, including the top supercomputer, on the Green500 list. We believe that our employees are our greatest assets, and they play a key role in creating long-term value for our stakeholders. As of the end of fiscal year 2024, we had approximately 29,600 employees in 36 countries, 22,200 were engaged in research and development and 7,400 were engaged in sales, marketing, operations, and administrative positions. The Compensation Committee of our Board of Directors assists in the oversight

\-- DOCUMENT 18 --

designed mentoring and development programs for women and employees from traditionally underrepresented groups to ensure widespread readiness for future advancement. To evaluate employee sentiment and engagement, we use pulse surveys, a suggestion box, and an anonymous third-party platform. Pulse surveys help us gain insight into employee experience and provides employee-generated ideas so that we can take targeted action. The suggestion box is an always-on, interactive tool where employees share their thoughts about making our company a better place to work. The anonymous third-party platform is designed to protect the identity of the reporter and provide a mechanism for reporters to follow an investigation and receive responses. We want NVIDIA to be a place where people can build their careers over their lifetime. Our employees tend to come and stay. In fiscal year 2024, our overall turnover rate was 2.7%. We offer comprehensive benefits to support our employees’ and their families’ physical health, well-being, and financial health. Programs include 401(k) programs in the U.S., statutory and supplemental pension programs outside the U.S., our employee stock purchase program, flexible work hours, and time off policies to address mental health, stress, and time-management challenges. We evaluate our benefit offerings globally and aim to provide comparable support across the regions where we

\-- DOCUMENT 19 --

of policies and strategies relating to human capital management. As the demand for global technical talent continues to be competitive, we have grown our technical workforce and have been successful in attracting top talent to NVIDIA. We have attracted talent globally through our strong employer brand and differentiated hiring strategies for college, professional, and leadership talent. Our workforce is 83% technical and 49% hold advanced degrees. Additionally, we have increased focus on diversity recruiting, resulting in an increase in global female hiring in each channel. Our own employees help to surface top talent, with over 40% of our new hires in fiscal year 2024 coming from employee referrals. To support employee development, we provide opportunities to learn on-the-job through training courses, targeted development programs, mentoring and peer coaching and ongoing feedback. We have a library of live and on-demand learning experiences that include workshops, panel discussions, and speaker forums. We create learning paths focused on our most common development needs and constantly upgrade our offerings to ensure that our employees are exposed to the most current content and technologies available. We offer tuition reimbursement programs to subsidize educational programs and advanced certifications. We implemented a career coaching service to provide one-on-one guidance to employees, and encourage internal job mobility. We have implemented specifically

\-- DOCUMENT 20 --

operate. We are committed to providing tailored benefits based on the needs of our Community Resource Groups and continuing our support for parents, both new birth parents and those who wish to become parents. Jen-Hsun Huang co-founded NVIDIA in 1993 and has served as our President, Chief Executive Officer, and a member of the Board of Directors since our inception. From 1985 to 1993, Mr. Huang was employed at LSI Logic Corporation, a computer chip manufacturer, where he held a variety of positions including as Director of Coreware, the business unit responsible for LSI's SOC. From 1983 to 1985, Mr. Huang was a microprocessor designer for AMD, a semiconductor company. Mr. Huang holds a B.S.E.E. degree from Oregon State University and an M.S.E.E. degree from Stanford University. Colette M. Kress joined NVIDIA in 2013 as Executive Vice President and Chief Financial Officer. Prior to NVIDIA, Ms. Kress most recently served as Senior Vice President and Chief Financial Officer of the Business Technology and Operations Finance organization at Cisco Systems, Inc., a networking equipment company, since 2010. At Cisco, Ms. Kress was responsible for financial strategy, planning,

\-- DOCUMENT 21 --

reporting and business development for all business segments, engineering and operations. From 1997 to 2010 Ms. Kress held a variety of positions at Microsoft, a software company, including, beginning in 2006, Chief Financial Officer of the Server and Tools division, where Ms. Kress was responsible for financial strategy, planning, reporting and business development for the division. Prior to joining Microsoft, Ms. Kress spent eight years at Texas Instruments Incorporated, a semiconductor company, where she held a variety of finance positions. Ms. Kress holds a B.S. degree in Finance from University of Arizona and an M.B.A. degree from Southern Methodist University. Ajay K. Puri joined NVIDIA in 2005 as Senior Vice President, Worldwide Sales and became Executive Vice President, Worldwide Field Operations in 2009. Prior to NVIDIA, he held positions in sales, marketing, and general management over a 22-year career at Sun Microsystems, Inc., a computing systems company. Mr. Puri previously held marketing, management consulting, and product development positions at Hewlett-Packard, an information technology company, Booz Allen Hamilton Inc., a management and technology consulting company, and Texas Instruments Incorporated. Mr. Puri holds a B

\-- DOCUMENT 22 --

.S.E.E. degree from the University of Minnesota, an M.S.E.E. degree from the California Institute of Technology and an M.B.A. degree from Harvard Business School. Debora Shoquist joined NVIDIA in 2007 as Senior Vice President of Operations and in 2009 became Executive Vice President of Operations. Prior to NVIDIA, Ms. Shoquist served from 2004 to 2007 as Executive Vice President of Operations at JDS Uniphase Corp., a provider of communications test and measurement solutions and optical products for the telecommunications industry. She served from 2002 to 2004 as Senior Vice President and General Manager of the Electro- Optics business at Coherent, Inc., a manufacturer of commercial and scientific laser equipment. Previously, she worked at Quantum Corp., a data protection company, as President of the Personal Computer Hard Disk Drive Division, and at Hewlett-Packard. Ms. Shoquist holds a B.S. degree in Electrical Engineering from Kansas State University and a B.S. degree in Biology from Santa Clara University. Timothy S. Teter joined NVIDIA in 2017 as Senior Vice President, General Counsel and Secretary and became Executive Vice President, General Counsel and Secretary in February 2018. Prior to NVIDIA

\-- DOCUMENT 23 --

, Mr. Teter spent more than two decades at the law firm of Cooley LLP, where he focused on litigating patent and technology related matters. Prior to attending law school, he worked as an engineer at Lockheed Missiles and Space Company, an aerospace company. Mr. Teter holds a B.S. degree in Mechanical Engineering from the University of California at Davis and a J.D. degree from Stanford Law School. Our annual reports on Form 10-K, quarterly reports on Form 10-Q, current reports on Form 8-K and, if applicable, amendments to those reports filed or furnished pursuant to Section 13(a) or 15(d) of the Securities Exchange Act of 1934, as amended, or the Exchange Act, are available free of charge on or through our website, http\://www\.nvidia.com, as soon as reasonably practicable after we electronically file such material with, or furnish it to, the Securities and Exchange Commission, or the SEC. The SEC’s website, http\://www\.sec.gov, contains reports, proxy and information statements, and other information regarding issuers that file electronically with the SEC. Our web site and the information on it or connected to it are not a part of this Annual

\-- DOCUMENT 24 --

Report on Form 10-K. The following risk factors should be considered in addition to the other information in this Annual Report on Form 10-K. The following risks could harm our business, financial condition, results of operations or reputation, which could cause our stock price to decline. Additional risks, trends and uncertainties not presently known to us or that we currently believe are immaterial may also harm our business, financial condition, results of operations or reputation. We have invested in research and development in markets where we have a limited operating history, which may not produce meaningful revenue for several years, if at all. If we fail to develop or monetize new products and technologies, or if they do not become widely adopted, our financial results could be adversely affected. Obtaining design wins may involve a lengthy process and depends on our ability to anticipate and provide features and functionality that customers will demand. They also do not guarantee revenue. Failure to obtain a design win may prevent us from obtaining future design wins in subsequent generations. We cannot ensure that the products and technologies we bring to market will provide value to our customers and partners. If we fail any of these key success criteria, our financial results may be harmed. We have begun offering enterprise customers NVIDIA DGX Cloud

\-- DOCUMENT 25 --

services directly and through our network of partners, which include cloud-based infrastructure, software and services for training and deploying AI models, and NVIDIA AI Foundations for customizable pretrained AI models. We have partnered with CSPs to host such software and services in their data centers, and we entered and may continue to enter into multi-year cloud service agreements to support these offerings and our research and development activities. The timing and availability of these cloud services has changed and may continue to change, impacting our revenue, expenses, and development timelines. NVIDIA DGX Cloud services may not be successful and will take time, resources, and investment. We also offer or plan to offer standalone software solutions, including NVIDIA AI Enterprise, NVIDIA Omniverse, NVIDIA DRIVE, and several other software solutions. These new business models or strategies may not be successful, and we may fail to sell any meaningful standalone software or services. We may incur significant costs and may not achieve any significant revenue from these offerings. Our target markets remain competitive, and competition may intensify with expanding and changing product and service offerings, industry standards, customer needs, new entrants and consolidations. Our competitors’ products, services and technologies, including those mentioned above in this Annual Report on Form 10-K, may be cheaper

\-- DOCUMENT 26 --

or provide better functionality or features than ours, which has resulted and may in the future result in lower-than-expected selling prices for our products. Some of our competitors operate their own fabrication facilities, and have longer operating histories, larger customer bases, more comprehensive IP portfolios and patent protections, more design wins, and greater financial, sales, marketing and distribution resources than we do. These competitors may be able to acquire market share and/or prevent us from doing so, more effectively identify and capitalize upon opportunities in new markets and end-user trends, more quickly transition their products, and impinge on our ability to procure sufficient foundry capacity and scarce input materials during a supply-constrained environment, which could harm our business. Some of our customers have in-house expertise and internal development capabilities similar to some of ours and can use or develop their own solutions to replace those we are providing. For example, others may offer cloud-based services that compete with our AI cloud service offerings, and we may not be able to establish market share sufficient to achieve the scale necessary to meet our business objectives. If we are unable to successfully compete in this environment, demand for our products, services and technologies could decrease and we may not establish meaningful revenue. We use third parties to manufacture and

\-- DOCUMENT 27 --

assemble our products, and we have long manufacturing lead times. We are not provided guaranteed wafer, component and capacity supply, and our supply deliveries and production may be non-linear within a quarter or year. If our estimates of customer demand are inaccurate, as we have experienced in the past, there could be a significant mismatch between supply and demand. This mismatch has resulted in both product shortages and excess inventory, has varied across our market platforms, and has significantly harmed our financial results. commitments for capacity to address our business needs, or our long-term demand expectations may change. These risks may increase as we shorten our product development cycles, enter new lines of business, or integrate new suppliers or components into our supply chain, creating additional supply chain complexity. Additionally, our ability to sell certain products has been and could be impeded if components necessary for the finished products are not available from third parties. This risk may increase as a result of our platform strategy. In periods of shortages impacting the semiconductor industry and/or limited supply or capacity in our supply chain, the lead times on our orders may be extended. We have previously experienced and may continue to experience extended lead times of more than 12 months. We have paid premiums and provided deposits to secure future supply and

\-- DOCUMENT 28 --

capacity, which have increased our product costs and may continue to do so. If our existing suppliers are unable to scale their capabilities to meet our supply needs, we may require additional sources of capacity, which may require additional deposits. We may not have the ability to reduce our supply commitments at the same rate or at all if our revenue declines. Demand for our data center systems and products surged in fiscal year 2024. Entering fiscal year 2025, we are gathering customer demand indications across several product transitions. We have demand visibility for our new data center products ramping later in fiscal year 2025. We have increased our supply and capacity purchases with existing suppliers, added new vendors and entered into prepaid manufacturing and capacity agreements. These increased purchase volumes, the number of suppliers, and the integration of new vendors into our supply chain may create more complexity and execution risk. We may continue to enter into new supplier and capacity arrangements. Our purchase commitments and obligations for inventory and manufacturing capacity at the end of fiscal year 2024 were impacted by shortening lead times for certain components. Supply of Hopper architecture products is improving, and demand remains very strong. We expect our next-generation products to be supply-constrained based upon demand indications. We may

\-- DOCUMENT 29 --

incur inventory provisions or impairments if our inventory or supply or capacity commitments exceed demand for our products or demand declines. Our customer orders and longer-term demand estimates may change or may not be correct, as we have experienced in the past. Product transitions are complex and can impact our revenue as we often ship both new and prior architecture products simultaneously and we and our channel partners prepare to ship and support new products. Due to our product introduction cycles, we are almost always in various stages of transitioning the architecture of our Data Center, Professional Visualization, and Gaming products. We will have a broader and faster Data Center product launch cadence to meet a growing and diverse set of AI opportunities. The increased frequency of these transitions may magnify the challenges associated with managing our supply and demand due to long manufacturing lead times. Qualification time for new products, customers anticipating product transitions and channel partners reducing channel inventory of prior architectures ahead of new product introductions can create reductions or volatility in our revenue. We have experienced and may in the future experience reduced demand for current generation architectures when customers anticipate transitions, and we may be unable to sell multiple product architectures at the same time for current and future architecture transitions. If we are unable to execute our architectural transitions as planned for any reason

\-- DOCUMENT 30 --

, our financial results may be negatively impacted. The increasing frequency and complexity of newly introduced products may result in unanticipated quality or production issues that could increase the magnitude of inventory provisions, warranty or other costs or result in product delays. Deployment of new products to customers creates additional challenges due to the complexity of our technologies, which has impacted and may in the future impact the timing of customer purchases or otherwise impact our demand. While we have managed prior product transitions and have previously sold multiple product architectures at the same time, these transitions are difficult, may impair our ability to predict demand and impact our supply mix, and we may incur additional costs. If we underestimate our customers' future demand for our products, our foundry partners may not have adequate lead-time or capacity to increase production and we may not be able to obtain sufficient inventory to fill orders on a timely basis. Even if we are able to increase supply to meet customer demand, we may not be able to do so in a timely manner, or our contract manufacturers may experience supply constraints. If we cannot procure sufficient supply to meet demand or otherwise fail to fulfill our customers’ orders on a timely basis, or at all, our customer relationships could be damaged, we could lose revenue and market share and our

\-- DOCUMENT 31 --

reputation could be harmed. Additionally, since some of our products are part of a complex data center buildout, supply constraints or availability issues with respect to any one component have had and may have a broader revenue impact. If we overestimate our customers’ future demand for our products, or if customers cancel or defer orders or choose to purchase from our competitors, we may not be able to reduce our inventory or other contractual purchase commitments. In the past, we have experienced a reduction in average selling prices, including due to channel pricing programs that we have implemented and may continue to implement, as a result of our overestimation of future demand, and we may need to continue these reductions. We have had to increase prices for certain of our products as a result of our suppliers’ increase in prices, and we may need to continue to do so for other products in the future. We have also written down our inventory, incurred cancellation penalties, and recorded impairments and may have to do so in the future. These impacts were amplified by our placement of non-cancellable and non-returnable purchasing terms well in advance of our historical lead times and could be exacerbated if we need to make changes to the design of future products. The risk of these impacts has increased

\-- DOCUMENT 32 --

and may continue to increase as our purchase obligations and prepaids have grown and are expected to continue to grow and become a greater portion of our total supply. All of these factors may negatively impact our gross margins and financial results. We build technology and introduce products for new and innovative use cases and applications, such as NVIDIA DGX Cloud services, NVIDIA AI Foundations, Omniverse platform, LLMs, and generative AI models. Our demand estimates for new use cases, applications, and services can be incorrect and create volatility in our revenue or supply levels, and we may not be able to generate significant revenue from these use cases, applications, and services. Recent technologies, such as generative AI models, have emerged, and while they have driven increased demand for Data Center, the long-term trajectory is unknown. Because our products may be used in multiple use cases and applications, it is difficult for us to estimate with any reasonable degree of precision the impact of generative AI models on our reported revenue or forecasted demand. Additionally, we started shipping our CPU product offerings, the Grace CPU and Grace Hopper Superchips, in the third quarter of fiscal year 2024. Our ability to adequately predict our CPU demand may create volatility in our

\-- DOCUMENT 33 --

revenue or supply levels. Challenges in estimating demand could become more pronounced or volatile in the future on both a global and regional basis. Extended lead times may occur if we experience other supply constraints caused by natural disasters, pandemics or other events. In addition, geopolitical tensions, such as those involving Taiwan and China, which comprise a significant portion of our revenue and where we have suppliers, contract manufacturers, and assembly partners who are critical to our supply continuity, could have a material adverse impact on us. The use of our GPUs other than that for which they were designed and marketed, including new and unexpected use cases, has impacted and can in the future impact demand for our products, including by leading to inconsistent spikes and drops in demand. For example, several years ago, our Gaming GPUs began to be used for mining digital currencies, such as Ethereum. It is difficult for us to estimate with any reasonable degree of precision the past or current impact of cryptocurrency mining, or forecast the future impact of cryptocurrency mining, on demand for our products. Volatility in the cryptocurrency market, including new compute technologies, price changes in cryptocurrencies, government cryptocurrency policies and regulations, new cryptocurrency standards and changes in the method of verifying blockchain transactions, has impacted and can in the future impact

\-- DOCUMENT 34 --

cryptocurrency mining and demand for our products and can further impact our ability to estimate demand for our products. Changes to cryptocurrency standards and processes including, but not limited to, the Ethereum 2.0 merge in 2022, have reduced and may in the future decrease the usage of GPUs for Ethereum mining. This has created and may in the future create increased aftermarket sales of our GPUs, which could negatively impact retail prices for our GPUs and reduce demand for our new GPUs. In general, our new products or previously sold products may be resold online or on the unauthorized “gray market,” which also makes demand forecasting difficult. Gray market products and reseller marketplaces compete with our new products and distribution channels. Additionally, we depend on developers, customers and other third parties to build, enhance, and maintain accelerated computing applications that leverage our platforms. We also rely on third-party content providers and publishers to make their content available on our platforms, such as GeForce NOW. Failure by developers, customers, and other third parties to build, enhance, and maintain applications that leverage our platforms, or failure by third-party content providers or publishers to make their content available on reasonable terms or at all for use by our customers or end users on our platforms, could adversely affect

\-- DOCUMENT 35 --

customer demand. We depend on foundries to manufacture our semiconductor wafers using their fabrication equipment and techniques. We do not assemble, test, or package our products, but instead contract with independent subcontractors. These subcontractors assist with procuring components used in our systems, boards, and products. We face several risks which have adversely affected or could adversely affect our ability to meet customer demand and scale our supply chain, negatively impact longer-term demand for our products and services, and adversely affect our business operations, gross margin, revenue and/or financial results, including: Our hardware and software product and service offerings are complex. They have in the past and may in the future contain defects or security vulnerabilities or experience failures or unsatisfactory performance due to any number of issues in design, fabrication, packaging, materials, bugs and/or use within a system. These risks may increase as our products are introduced into new devices, markets, technologies and applications or as new versions are released. These risks further increase when we rely on partners to supply and manufacture components that are used in our products, as these arrangements reduce our direct control over production. AI software products we or our partners offer rely on training data that may originate from third parties and new training methods, and

\-- DOCUMENT 36 --

the resulting products may contain unknown or undetected defects and errors, or reflect unintended bias. Although arrangements with component providers may contain provisions for product defect expense reimbursement, we generally remain responsible to the customer for warranty product defects that may occur from time to time. Some failures in our products or services have been in the past and may in the future be only discovered after a product or service has been shipped or used. Undiscovered vulnerabilities in our products or services could result in loss of data or intangible property, or expose our customers to unscrupulous third parties who develop and deploy malicious software programs that could attack our products or services. Defects or failure of our offerings to perform to specifications could lead to substantial damage to the products in which our offerings have been integrated by OEMs, ODMs, AIBs and automotive manufacturers and tier 1 automotive suppliers, and to the user of such end product. Any such defect may cause us to incur significant warranty, support and repair or replacement costs as part of a product recall or otherwise, write-off the value of related inventory, and divert the attention of our engineering and management personnel from our product development efforts to find and correct the issue. Our efforts to remedy these issues may not

\-- DOCUMENT 37 --

be timely or satisfactory to our customers. An error or defect in new products, releases or related software drivers after commencement of commercial shipments could result in failure to achieve market acceptance, loss of design wins, temporary or permanent withdrawal from a product or market and harm to our relationships with existing and prospective customers and partners and consumers’ perceptions of our brand, which would in turn negatively impact our business operations, gross margin, revenue and/or financial results. We may be required to reimburse our customers, partners or consumers, including for costs to repair or replace products in the field or in connection with indemnification obligations, or pay fines imposed by regulatory agencies. For example, in fiscal year 2023, a defect was identified in a third-party component embedded in certain Data Center products. This defect has had, and other defects may in the future have, an adverse effect on our cost and supply of components and finished goods. These costs could be significant in future periods. We recorded a net warranty liability during fiscal year 2023 primarily in connection with this defect. While we believe we have accurately recorded for warranty obligations, we may need to record additional amounts in the future if our estimate proves to be incorrect. In general, if a product liability claim regarding any of

\-- DOCUMENT 38 --

our products is brought against us, even if the alleged damage is due to the actions or inactions of a third party, such as within our supply chain, the cost of defending the claim could be significant and would divert the efforts of our technical and management personnel and harm our business. Further, our business liability insurance may be inadequate or future coverage may be unavailable on acceptable terms, which could adversely impact our financial results. Additionally, we maintain an investment portfolio of various holdings, types, and maturities. These investments are subject to general credit, liquidity, market and interest rate risks, which may be exacerbated by market downturns or events that affect global financial markets, as described above. A majority of our investment portfolio comprises USG securities. A decline in global financial markets for long periods or a downgrade of the USG credit rating due to an actual or threatened default on government debt could result in higher interest rates, a decline in the value of the U.S. dollar, reduced market liquidity or other adverse conditions. These factors could cause an unrealized or realized loss position in our investments or require us to record impairment charges. We sell our products internationally, and we also have operations and conduct business internationally. Our semiconductor wafers are manufactured,

\-- DOCUMENT 39 --

assembled, tested and packaged by third parties located outside of the United States, and we generated 56% of our revenue in fiscal year 2024 from sales outside of the United States. Our sales to China decreased as a percentage of total Data Center revenue from 19% in fiscal year 2023 to 14% in fiscal year 2024. Although we have not received licenses from the USG to ship restricted products to China, we have started to ship alternatives to the China market in small volumes. China represented a mid-single digit percentage of our Data Center revenue in the fourth quarter of fiscal year 2024 due to USG licensing requirements and we expect China to be in a similar range in the first quarter of fiscal year 2025. The global nature of our business subjects us to a number of risks and uncertainties, which have had in the past and could in the future have a material adverse effect on our business, financial condition and results of operations. These include domestic and international economic and political conditions in countries in which we and our suppliers and manufacturers do business, government lockdowns to control case spread of global or local health issues, differing legal standards with respect to protection of IP and employment practices, different domestic and international business and

\-- DOCUMENT 40 --

cultural practices, disruptions to capital markets, counter-inflation policies, currency fluctuations, natural disasters, acts of war or other military actions, terrorism, public health issues and other catastrophic events. Individuals, groups of hackers and sophisticated organizations, including nation-states and nation-state-supported actors, and other threat actors have engaged and are expected to continue to engage in cyber-attacks. Additionally, some actors are using AI technology to launch more automated, targeted and coordinated attacks. Due to geopolitical conflicts and during times of war or other major conflicts, we and the third parties we rely upon may be vulnerable to a heightened risk of cyber-attacks that could materially disrupt our ability to provide services and products. We may also face cybersecurity threats due to error or intentional misconduct by employees, contractors or other third-party service providers. Certain aspects of effective cybersecurity are dependent upon our employees, contractors and/or other third-party service providers safeguarding our sensitive information and adhering to our security policies and access control mechanisms. We have in the past experienced, and may in the future experience, security incidents arising from a failure to properly handle sensitive information or adhere to our security policies and access control mechanisms and, although no such events have had a material adverse effect on our business, there can be no

\-- DOCUMENT 41 --

assurance that an insider threat will not result in an incident that is material to us. Furthermore, we rely on products and services provided by third-party suppliers to operate certain critical business systems, including without limitation, cloud-based infrastructure, encryption and authentication technology, employee email and other functions, which exposes us to supply-chain attacks or other business disruptions. We cannot guarantee that third parties and infrastructure in our supply chain or our partners’ supply chains have not been compromised or that they do not contain exploitable vulnerabilities, defects or bugs that could result in a breach of or disruption to our information technology systems, including our products and services, or the third-party information technology systems that support our services. We may also incorporate third-party data into our AI algorithms or use open-source datasets to train our algorithms. These datasets may be flawed, insufficient, or contain certain biased information, and may otherwise be vulnerable to security incidents. We may have limited insight into the data privacy or security practices of third-party suppliers, including for our AI algorithms. Our ability to monitor these third parties’ information security practices is limited, and they may not have adequate information security measures in place. In addition, if one of our third-party suppliers suffers a security incident (which has happened in the To

\-- DOCUMENT 42 --

defend against security incidents, we must continuously engineer more secure products and enhance security and reliability features, which is expected to result in increased expenses. We must also continue to develop our security measures, including training programs and security awareness initiatives, designed to ensure our suppliers have appropriate security measures in place, and continue to meet the evolving security requirements of our customers, applicable industry standards, and government regulations. While we invest in training programs and security awareness initiatives and take steps to detect and remediate certain vulnerabilities that we have identified, we may not always be able to prevent threats or detect and mitigate all vulnerabilities in our security controls, systems or software, including third-party software we have installed, as such threats and techniques change frequently and may not be detected until after a security incident has occurred. Further, we may experience delays in developing and deploying remedial measures designed to address identified vulnerabilities. These vulnerabilities could result in reputational and financial harm, and if exploited, these vulnerabilities could result in a security incident. We hold confidential, sensitive, personal and proprietary information, including information from partners and customers. Breaches of our security measures, along with reported or perceived vulnerabilities or unapproved dissemination of proprietary information or sensitive or confidential data about us or third parties, could expose us

\-- DOCUMENT 43 --

and the parties affected to a risk of loss, or misuse of this information, potentially resulting in litigation and subsequent liability, regulatory inquiries or actions, damage to our brand and reputation or other harm, including financial, to our business. For example, we hold proprietary game source code from third-party partners in our GFN service. Breaches of our GFN security measures, which have happened in the past, could expose our partners to a risk of loss or misuse of this source code, damage both us and our partners, and expose NVIDIA to potential litigation and liability. If we or a third party we rely on experience a security incident, which has occurred in the past, or are perceived to have experienced a security incident, we may experience adverse consequences, including government enforcement actions, additional reporting requirements and/or oversight, restrictions on processing data, litigation, indemnification obligations, reputational harm, diversion of funds, diversion of management attention, financial loss, loss of data, material disruptions in our systems and operations, supply chain, and ability to produce, sell and distribute our goods and services, and other similar harms. Inability to fulfill orders, delayed sales, lower margins or lost customers as a result of these disruptions could adversely affect our financial results, stock

\-- DOCUMENT 44 --

price and reputation. Applicable data privacy and security obligations may require us to notify relevant stakeholders, including affected individuals, customers, regulators and investors, of security incidents, and mandatory disclosure of such incidents could lead to negative publicity. In addition to experiencing a security incident, third parties may gather, collect or infer sensitive information about us from public sources, data brokers or other means that reveals competitively sensitive details about our organization and could be used to harm our business. Our worldwide operations could be disrupted by natural disasters and extreme weather conditions, power or water shortages, telecommunications failures, supplier disruptions, terrorist attacks, acts of violence, political and/or civil unrest, acts of war or other military actions, epidemics or pandemics, abrupt regulatory deterioration, and other natural or man-made disasters and catastrophic events. Our corporate headquarters, a large portion of our current data center capacity, and a portion of our research and development activities are located in California, and other critical business operations, finished goods inventory and some of our suppliers are located in Asia, making our operations vulnerable to natural disasters such as earthquakes, wildfires or other business disruptions occurring in these geographical areas. Catastrophic events can also have an impact on third-party vendors who provide us critical infrastructure services for IT and

\-- DOCUMENT 45 --

research and development systems and personnel. Our business continuity and disaster recovery planning may not be sufficient for all eventualities. Geopolitical and domestic political developments and other events beyond our control, can increase economic volatility globally. Political instability, changes in government or adverse political developments in or around any of the major countries in which we do business may harm our business, financial condition and results of operations. Worldwide geopolitical tensions and conflicts, including but not limited to China, Hong Kong, Israel, Korea and Taiwan where the manufacture of our product components and final assembly of our products are concentrated may result in changing regulatory requirements, and other disruptions that could impact our operations and operating strategies, product demand, access to global markets, hiring, and profitability. For example, other countries have restricted and may continue in the future to restrict business with the State of Israel, where we have engineering, sales support operations and manufacturing, and companies with Israeli operations, including by economic boycotts. Our operations could be harmed and our costs could increase if manufacturing, logistics or other operations are disrupted for any reason, including natural disasters, high heat events or water shortages, power shortages, information technology system failures or cyber-attacks, military actions or economic, business, labor, environmental, public health

\-- DOCUMENT 46 --

, or political issues. The ultimate impact on us, our third-party foundries and other suppliers of being located and consolidated in certain geographical areas is unknown. In the event a disaster, war or catastrophic event affects us, the third-party systems on which we rely, or our customers, our business could be harmed as a result of declines in revenue, increases in expenses, and substantial expenditures and time spent to fully resume operations. All of these risks and conditions could materially adversely affect our future sales and operating results. We are monitoring the impact of the geopolitical conflict in and around Israel on our operations, including the health and safety of our approximately 3,700 employees in the region who primarily support the research and development, operations, and sales and marketing of our networking products. Our operating expenses in fiscal year 2024 include expenses for financial support to impacted employees and charitable activity. We believe our global supply chain for our networking products has not experienced any significant impact. Further, in connection with the conflict, a substantial number of our employees in the region have been called-up for active military duty in Israel. Accordingly, some of our employees in Israel have been absent for an extended period and they or others may continue to be absent, which may cause disruption to

\-- DOCUMENT 47 --

our product development or operations. We did not experience any significant impact or expense to our business; however, if the conflict is further extended, it could impact future product development, operations, and revenue or create other uncertainty for our business. Additionally, interruptions or delays in services from CSPs, data center co-location partners, and other third parties on which we rely, including due to the events described above or other events such as the insolvency of these parties, could impair our ability to provide our products and services and harm our business. As we increase our reliance on these third-party systems and services, our exposure to damage from service interruptions, defects, disruptions, outages, shortages and other performance and quality problems may increase. Data centers depend on access to clean water and predictable energy. Power or water shortages, or regulations that limit energy or water availability, could impair the ability of our customers to expand their data center capacity and consume our products and services. Climate change may have an increasingly adverse impact on our business and on our customers, partners and vendors. Water and energy availability and reliability in the regions where we conduct business is critical, and certain of our facilities may be vulnerable to the impacts of extreme weather events. Extreme heat and wind coupled with dry

\-- DOCUMENT 48 --

conditions in Northern California may lead to power safety shut offs due to wildfire risk, which can have adverse implications for our Santa Clara, California headquarter offices and data centers, including impairing the ability of our employees to work effectively. Climate change, its impact on our supply chain and critical infrastructure worldwide and its potential to increase political instability in regions where we, our customers, partners and our vendors do business, may disrupt our business and cause us to experience higher attrition, losses and costs to maintain or resume operations. Although we maintain insurance coverage for a variety of property, casualty, and other risks, the types and amounts of insurance we obtain vary depending on availability and cost. Some of our policies have large deductibles and broad exclusions, and our insurance providers may be unable or unwilling to pay a claim. Losses not covered by insurance may be large, which could harm our results of operations and financial condition. Our business and those of our suppliers and customers may also be subject to climate-related laws, regulations and lawsuits. New or proposed regulations relating to carbon taxes, fuel or energy taxes, pollution limits, sustainability-related disclosure and governance and supply chain governance could result in greater direct costs, including costs associated with changes to manufacturing processes or the procurement of

\-- DOCUMENT 49 --

raw materials used in manufacturing processes, increased capital expenditures to improve facilities and equipment, and higher compliance and energy costs to reduce emissions, other compliance costs, as well as greater indirect costs resulting from our customers and/or suppliers incurring additional compliance costs that are passed on to us. These costs and restrictions could harm our business and results of operations by increasing our expenses or requiring us to alter our operations and product design activities. Stakeholder groups may find us insufficiently responsive to the implications of climate change, and therefore we may face legal action or reputational harm. We may not achieve our stated sustainability-related goals, which could harm our reputation, or we may incur additional, unexpected costs to achieve such goals. We may also experience contractual disputes due to supply chain delays arising from climate change-related disruptions, which could result in increased litigation and costs. We have acquired and invested and may continue to do so in businesses that offer products, services and technologies that we believe will help expand or enhance our strategic objectives. Acquisitions or investments involve significant challenges and risks and could impair our ability to grow our business, develop new products or sell our products and ultimately could have a negative impact on our financial results. If we pursue a particular transaction, we may limit our ability to

\-- DOCUMENT 50 --

enter into other transactions that could help us achieve our other strategic objectives. If we are unable to timely complete acquisitions, including due to delays and challenges in obtaining regulatory approvals, we may be unable to pursue other transactions, we may not be able to retain critical talent from the target company, technology may evolve and make the acquisition less attractive, and other changes can take place, which could reduce the anticipated benefits of the transaction and negatively impact our business. Regulators could also impose conditions that reduce the ultimate value of our acquisitions. In addition, to the extent that our perceived ability to consummate acquisitions has been harmed, future acquisitions may be more difficult, complex or expensive. Further, our investments in publicly traded companies could create volatility in our results and may generate losses up to the value of the investment. In addition, we have invested and may continue to invest in private companies to further our strategic objectives and to support certain key business initiatives. These companies can include early-stage companies still defining their strategic direction. Many of the instruments in which we invest are non-marketable and illiquid at the time of our initial investment, and we are not always able to achieve a return. To the extent any of the companies in which we invest are not successful, we could

\-- DOCUMENT 51 --

recognize an impairment and/or lose all or part of our investment. Our investment portfolio contains industry sector concentration risks, and a decline in any one or multiple industry sectors could increase our impairment losses. We face additional risks related to acquisitions and strategic investments, including the diversion of capital and other resources, including management’s attention; difficulty in realizing a satisfactory return and uncertainties to realize the benefits of an acquisition or strategic investment, if at all; difficulty or inability in obtaining governmental, regulatory approval or restrictions or other consents and approvals or financing; legal proceedings initiated as a result of an acquisition or investment; and potential failure of our due diligence processes to identify significant issues with the assets or company in which we are investing or are acquiring. We receive a significant amount of our revenue from a limited number of customers within our distribution and partner network. Sales to one customer, Customer A, represented 13% of total revenue for fiscal year 2024, which was attributable to the Compute & Networking segment. With several of these channel partners, we are selling multiple products and systems in our portfolio through their channels. Our operating results depend on sales within our partner network, as well as the ability of these partners to sell products that incorporate our processors. In the future, these

\-- DOCUMENT 52 --

partners may decide to purchase fewer products, not to incorporate our products into their ecosystem, or to alter their purchasing patterns in some other way. Because most of our sales are made on a purchase order basis, our customers can generally cancel, change or delay product purchase commitments with little notice to us and without penalty. Our partners or customers may develop their own solutions; our customers may purchase products from our competitors; and our partners may discontinue sales or lose market share in the markets for which they purchase our products, all of which may alter partners’ or customers’ purchasing patterns. Many of our customers often do not purchase directly from us but purchase through multiple OEMs, ODMs, system integrators, distributors and other channel partners. One indirect customer which primarily purchases our products through system integrators and distributors, including through Customer A, is estimated to have represented approximately 19% of total revenue for fiscal year 2024, attributable to the Compute & Networking segment. If end demand increases or our finished goods supply availability is concentrated near a quarter end, the system integrators, distributors and channel partners may have limited ability to increase their credit, which could impact the timing and amount of our revenue. The loss of any of our large customers, a

\-- DOCUMENT 53 --

significant reduction in purchases by them, our inability to sell to a customer due to U.S. or other countries’ trade restrictions or any difficulties in collecting accounts receivable would likely harm our financial condition and results of operations. To be competitive and execute our business strategy successfully, we must attract, retain and motivate our executives and key employees and recruit and develop capable and diverse talent. Labor is subject to external factors that are beyond our control, including our industry’s highly competitive market for skilled workers and leaders, cost inflation and workforce participation rates. Changes in immigration and work permit regulations or in their administration or interpretation could impair our ability to attract and retain qualified employees. Competition for personnel results in increased costs in the form of cash and stock- based compensation, and in times of stock price volatility, as we have experienced in the past and may experience in the future, the retentive value of our stock- based compensation may decrease. Additionally, we are highly dependent on the services of our longstanding executive team. Failure to ensure effective succession planning, transfer of knowledge and smooth transitions involving executives and key employees could hinder our strategic planning and execution and long-term success. We rely upon internal processes and information systems to support key business functions, including our assessment of internal controls

\-- DOCUMENT 54 --

over financial reporting as required by Section 404 of the Sarbanes-Oxley Act. The efficient operation and scalability of these processes and systems is critical to support our growth. We continue to design and implement updated accounting functionality related to a new enterprise resource planning, or ERP, system. Any ERP system implementation may introduce problems, such as quality issues or programming errors, that could have an impact on our continued ability to successfully operate our business or to timely and accurately report our financial results. These changes may be costly and disruptive to our operations and could impose substantial demands on management time. Failure to implement new or updated controls, or difficulties encountered in their implementation, could harm our operating results or cause us to fail to meet our reporting obligations. Our operating results have in the past fluctuated and may continue to fluctuate due to numerous of these risk factors. Therefore, investors should not rely on our past results of operations as an indication of our future performance. Additional factors that could affect our results of operations include, but are not limited to: Any of the factors discussed above could prevent us from achieving our anticipated financial results. For example, we have granted and may continue to grant extended payment terms to some customers, particularly during macroeconomic downturns, which

\-- DOCUMENT 55 --

could impact our ability to collect payment. Our vendors have requested and may continue to ask for shorter payment terms, which may impact our cash flow generation. These arrangements reduce the cash we have available for general business operations. In addition, the pace of growth in our operating expenses and investments may lag our revenue growth, creating volatility or periods where profitability levels may not be sustainable. Failure to meet our expectations or the expectations of our investors or security analysts is likely to cause our stock price to decline, as it has in the past, or experience substantial price volatility. We are subject to laws and regulations domestically and worldwide, affecting our operations in areas including, but not limited to, IP ownership and infringement; taxes; import and export requirements and tariffs; anti-corruption, including the Foreign Corrupt Practices Act; business acquisitions; foreign exchange controls and cash repatriation restrictions; data privacy requirements; competition and antitrust; advertising; employment; product regulations; cybersecurity; environmental, health, and safety requirements; the responsible use of AI; sustainability; cryptocurrency; and consumer laws. Compliance with such requirements can be onerous and expensive, could impact our competitive position, and may negatively impact our business operations and ability to manufacture and ship our products. There can be

\-- DOCUMENT 56 --

no assurance that our employees, contractors, suppliers, customers or agents will not violate applicable laws or the policies, controls, and procedures that we have designed to help ensure compliance with such laws, and violations could result in fines, criminal sanctions against us, our officers, or our employees, prohibitions on the conduct of our business, and damage to our reputation. Changes to the laws, rules and regulations to which we are subject, or changes to their interpretation and enforcement, could lead to materially greater compliance and other costs and/or further restrictions on our ability to manufacture and supply our products and operate our business. For example, we may face increased compliance costs as a result of changes or increases in antitrust legislation, regulation, administrative rule making, increased focus from regulators on cybersecurity vulnerabilities and risks. Our position in markets relating to AI has led to increased interest in our business from regulators worldwide, including the European Union, the United States, the United Kingdom and China. For example, the French Competition Authority collected information from us regarding our business and competition in the graphics card and cloud service provider market as part of an ongoing inquiry into competition in those markets. We have also received requests for information from regulators in the European Union, the United Kingdom, and China regarding

\-- DOCUMENT 57 --

our sales of GPUs, our efforts to allocate supply, foundation models and our investments, partnerships and other agreements with companies developing foundation models, and we expect to receive additional requests for information in the future. Governments and regulators are considering imposing restrictions on the hardware, software, and systems used to develop frontier foundation models and generative AI. If implemented, such restrictions could increase the costs and burdens to us and our customers, delay or halt deployment of new systems using our products, and reduce the number of new entrants and customers, negatively impacting our business and financial results. Revisions to laws or regulations or their interpretation and enforcement could also result in increased taxation, trade sanctions, the imposition of or increase to import duties or tariffs, restrictions and controls on imports or exports, or other retaliatory actions, which could have an adverse effect on our business plans or impact the timing of our shipments. Additionally, changes in the public perception of governments in the regions where we operate or plan to operate could negatively impact our business and results of operations. Government actions, including trade protection and national and economic security policies of U.S. and foreign government bodies, such as tariffs, import or export regulations, including deemed export restrictions and restrictions on the activities of U.S. persons

\-- DOCUMENT 58 --

, trade and economic sanctions, decrees, quotas or other trade barriers and restrictions could affect our ability to ship products, provide services to our customers and employees, do business without an export license with entities on the U.S. Department of Commerce’s U.S. Entity List or other USG restricted parties lists (which is expected to change from time to time), and generally fulfill our contractual obligations and have a material adverse effect on our business. If we were ever found to have violated export control laws or sanctions of the U.S. or similar applicable non-U.S. laws, even if the violation occurred without our knowledge, we may be subject to various penalties available under the laws, any of which could have a material and adverse impact on our business, operating results and financial condition. For example, in response to the war in Ukraine, the United States and other jurisdictions imposed economic sanctions and export control measures which blocked the passage of our products, services and support into Russia, Belarus, and certain regions of Ukraine. In fiscal year 2023, we stopped direct sales to Russia and closed business operations in Russia. Concurrently, the war in Ukraine has impacted sales in EMEA and may continue to do so in the future. Such restrictions could include additional

\-- DOCUMENT 59 --

unilateral or multilateral export controls on certain products or technology, including but not limited to AI technologies. As geopolitical tensions have increased, semiconductors associated with AI, including GPUs and associated products, are increasingly the focus of export control restrictions proposed by stakeholders in the U.S. and its allies. The United States has imposed unilateral controls restricting GPUs and associated products, and it is likely that additional unilateral or multilateral controls will be adopted. Such controls have been and may again be very broad in scope and application, prohibit us from exporting our products to any or all customers in one or more markets, including but not limited to China, and could negatively impact our manufacturing, testing and warehousing locations and options, or could impose other conditions that limit our ability to serve demand abroad and could negatively and materially impact our business, revenue and financial results. Export controls targeting GPUs and semiconductors associated with AI, which have been imposed and are increasingly likely to be further tightened, would further restrict our ability to export our technology, products, or services even though competitors may not be subject to similar restrictions, creating a competitive disadvantage for us and negatively impacting our business and financial results.

`,
  shortContext: `
Export controls targeting GPUs and semiconductors associated with AI, which have been imposed and are increasingly likely to be further tightened, would further restrict our ability to export our technology, products, or services even though competitors may not be subject to similar restrictions, creating a competitive disadvantage for us and negatively impacting our business and financial results.
`,
  task: 'Answer the question using the information provided in the documents.',
  queston: 'What percentage of employees left the company during the FY 2024?',
};

export interface RagTaskExampleData {
  id: number;
  task: string;
  dataset: string;
  model: string;
  prompt: Prompt;
  response: string;
  answer: string;
  isResponseCorrect: string;
  contextAdherence: number;
}

export const ragTaskExamplesData: RagTaskExampleData[] = [
  {
    id: 1,
    task: 'SCR',
    dataset: 'Drop',
    model: 'Llama-3.1-70b-chat',
    prompt: {
      context:
        'Context: In the city, the population was distributed as 28.3% under the age of 18, 11.3% from 18 to 24, 32.7% from 25 to 44, 18.2% from 45 to 64, and 9.6% who were 65 years of age or older. The median age was 31 years. For every 100 females, there were 97.3 males. For every 100 females age 18 and over, there were 94.5 males.\n\n',
      task: 'Answer the question using the information in the context.',
      queston: 'Which gender group is larger: females or males?',
      shortContext:
        'For every 100 females age 18 and over, there were 94.5 males.',
    },
    response:
      'Based on the information, females are the larger gender group. For every 100 females, there were 97.3 males, and for every 100 females age 18 and over, there were 94.5 males. This indicates that there are more females than males in the population.',
    answer: 'Female',
    isResponseCorrect: 'Yes',
    contextAdherence: 1,
  },
  {
    id: 2,
    task: 'SCR',
    dataset: 'Drop',
    model: 'Gemma-2-9b-it',
    prompt: {
      context:
        'Context: In the city, the population was distributed as 28.3% under the age of 18, 11.3% from 18 to 24, 32.7% from 25 to 44, 18.2% from 45 to 64, and 9.6% who were 65 years of age or older. The median age was 31 years. For every 100 females, there were 97.3 males. For every 100 females age 18 and over, there were 94.5 males.',
      queston: 'Which gender group is larger: females or males?',
      task: 'Answer the question using the information in the context.',
      shortContext:
        'For every 100 females age 18 and over, there were 94.5 males.',
    },

    response: 'Male',
    answer: 'Female',
    isResponseCorrect: 'No',
    contextAdherence: 0,
  },
  {
    id: 3,
    task: 'MCR',
    dataset: '5k',
    model: 'Gemini Flash',
    prompt: longPrompt,
    response:
      'The answer is **2.7%**. \n\n\nThis information is found in **Document 2**, which states: "In fiscal year 2024, our overall turnover rate was 2.7%.',
    answer: '2.7%',
    isResponseCorrect: 'Yes',
    contextAdherence: 1,
  },
  {
    id: 4,
    task: 'MCR',
    dataset: '15k',
    model: 'Qwen-2-7b-Instruct',
    prompt: longPrompt,
    response:
      'The documents do not provide the information about the percentage of employees who left the company during FY 2024.',
    answer: '2.7%',
    isResponseCorrect: 'No',
    contextAdherence: 0,
  },
  {
    id: 5,
    task: 'LCR',
    dataset: '100k',
    model: 'Claude 3.5 Sonnet',
    prompt: longPrompt,
    response:
      "According to the document, NVIDIA's overall employee turnover rate in fiscal year 2024 was 2.7%.",
    answer: '2.7%',
    isResponseCorrect: 'Yes',
    contextAdherence: 1,
  },
  {
    id: 6,
    task: 'LCR',
    dataset: '60k',
    model: 'Mistral Large',
    prompt: longPrompt,
    response: 'The overall turnover rate was 5.3%.',
    answer: '2.7%',
    isResponseCorrect: 'No',
    contextAdherence: 0,
  },
];
