import React from 'react';
import { InView, useInView } from 'react-intersection-observer';

import { h2Style } from '@/components/hallucination-index-2024/common';
import Navbar from './navbar';
import SCR from './scr';
import MCR from './mcr';
import LCR from './lcr';
import { IMcrLcrModel, IScrModel } from '../../model-data-helpers';
import { ragTaskExamplesData } from './rag-task-examples-data';

const Tabs: React.FC<{
  data: {
    scrModels: IScrModel[];
    mcrModels: IMcrLcrModel[];
    lcrModels: IMcrLcrModel[];
  };
}> = ({ data }) => {
  const { scrModels, mcrModels, lcrModels } = data;

  const scrRagTaskExamples = ragTaskExamplesData.filter(
    (i) => i.task === 'SCR',
  );
  const mcrRagTaskExamples = ragTaskExamplesData.filter(
    (i) => i.task === 'MCR',
  );
  const lcrRagTaskExamples = ragTaskExamplesData.filter(
    (i) => i.task === 'LCR',
  );
  const sections = [
    {
      id: 'scr',
      title: 'Short Context RAG (SCR)',
      component: (
        <SCR scrModels={scrModels} ragTaskExamples={scrRagTaskExamples} />
      ),
    },
    {
      id: 'mcr',
      title: 'Medium Context RAG (MCR)',
      component: (
        <MCR mcrModels={mcrModels} ragTaskExamples={mcrRagTaskExamples} />
      ),
    },
    {
      id: 'lcr',
      title: 'Long Context RAG (LCR)',
      component: (
        <LCR lcrModels={lcrModels} ragTaskExamples={lcrRagTaskExamples} />
      ),
    },
  ];

  const [visibleSection, setVisibleSection] = React.useState(sections[0].id);

  const { ref } = useInView({
    threshold: 0.2,
  });

  const setInView = (inView, entry) => {
    if (inView) {
      setVisibleSection(entry.target.getAttribute('id'));
    }
  };

  return (
    <section className="mx-auto flex max-w-[90rem] flex-col gap-8 px-5 py-10 lg:px-[85px] lg:pb-16 lg:pt-32">
      <h2
        className={`${h2Style} !font-inter font-semibold leading-[normal] md:!text-[64px]`}
      >
        Model Performance on RAG Tasks
      </h2>

      <div className="sticky top-20 z-30 w-full">
        <Navbar items={sections} visibleSection={visibleSection} />
      </div>

      <div ref={ref}>
        {sections.map((section) => (
          <InView onChange={setInView} key={section.id}>
            {({ ref }) => {
              return (
                <div id={section.id} ref={ref}>
                  {section.component}
                </div>
              );
            }}
          </InView>
        ))}
      </div>
    </section>
  );
};

export default Tabs;
