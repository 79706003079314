import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { h4Style } from '@/components/hallucination-index-2024/common';
import RagTaskExampleItem from './rag-task-example-item';
import { RagTaskExampleData } from '../rag-task-examples-data';

type Props = {
  items: RagTaskExampleData[];
};

const RagTaskExamples = ({ items }: Props) => {
  const [open, setOpen] = React.useState(true);
  return (
    <>
      <section className="mt-10 w-full">
        {/* Title */}
        <div
          className="flex cursor-pointer flex-row items-center gap-4 border-b border-b-black/20 py-2"
          onClick={() => setOpen(!open)}
          tabIndex={0}
          role="group"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setOpen(!open);
            }
          }}
        >
          <h4 className={`${h4Style} mb-3`}>RAG Task Examples</h4>
          {open ? (
            <FiChevronUp className="h-9 w-9" />
          ) : (
            <FiChevronDown className="h-9 w-9" />
          )}
        </div>

        {open && (
          <section className="w-full">
            {items.map((item) => (
              <RagTaskExampleItem key={item.id} data={item} />
            ))}
          </section>
        )}
      </section>
    </>
  );
};

export default RagTaskExamples;
