import AppTooltip from '@/components/common/app-tooltip';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { pNormal } from '../../common';
import { Link } from 'gatsby';

const TopModelSection: React.FC<{
  categoryTitle: string;
  categorySubtitle: string;
  contextList: {
    label: string;
    icon: JSX.Element;
    text: string;
    value: string;
    link: string;
  }[];
  showHeader?: boolean;
}> = ({ categoryTitle, categorySubtitle, contextList, showHeader }) => {
  const cellPadding = 'p-5';
  const isMiddleListItemClassname = (index: number) => {
    const borderY = 'border-t border-b border-gray-400/40';
    const borderT = 'border-t border-gray-400/40';
    if (index !== contextList.length - 1 && index !== 0) return borderY;

    if (contextList.length === 2 && index === 1) return borderT;

    return '';
  };

  return (
    <>
      <section className="mx-auto hidden max-w-[90rem] flex-col px-5 md:flex lg:px-[85px]">
        <div className="flex flex-col gap-6 border border-t-0 border-gray-400/40 pt-6 md:flex-row">
          {/* Table */}
          <div className="grid w-full grid-cols-12 !text-white">
            {showHeader && (
              <>
                {/* Header */}
                <div
                  className={`col-span-4 border-b border-gray-400/40 pb-2 pl-5 text-[#FFFFFF99]`}
                >
                  Category
                </div>
                <div
                  className={`col-span-2 border-b border-gray-400/40 pb-2 pl-5 text-[#FFFFFF99]`}
                >
                  Provider
                </div>
                <div
                  className={`col-span-3 border-b border-gray-400/40 pb-2 pl-5 text-[#FFFFFF99]`}
                >
                  Description
                </div>
                <div
                  className={`col-span-3 flex gap-1 border-b border-gray-400/40 pb-2 pl-5 text-[#FFFFFF99]`}
                >
                  Score
                  <AppTooltip
                    id="score-tooltip"
                    content="Context Adherence is a measurement of closed-domain hallucinations: cases where your model said things that were not provided in the context.If a response is adherent to the context (i.e. it has a value of 1 or close to 1), it only contains information given in the context. If a response is not adherent (i.e. it has a value of 0 or close to 0), it's likely to contain facts not included in the context provided to the model."
                  >
                    <img
                      className="cursor-pointer"
                      src="/info-icon.svg"
                      alt="Info icon"
                    />
                  </AppTooltip>
                </div>
              </>
            )}
            {/* Body */}
            <div
              className={`col-span-4 row-span-10 border-t border-t-black/20`}
            >
              <div className={`flex flex-col ${cellPadding}`}>
                <h4 className="pt-4 text-xl font-medium leading-7 md:text-[22px]">
                  {categoryTitle}
                  <br />
                  {categorySubtitle})
                </h4>
                <a
                  href="#"
                  className="mt-5 flex items-center text-base font-semibold leading-6 text-white"
                >
                  Learn more <FiChevronRight className="h-8 w-8 !text-white" />
                </a>
              </div>
            </div>
            {/* Right side table cells */}
            {contextList.map((item, index) => {
              const middleListItemClassname = isMiddleListItemClassname(index);
              return (
                <Link to={item.link} className="col-span-8 grid grid-cols-8">
                  <div
                    className={`${cellPadding} ${middleListItemClassname} col-span-2 flex items-center`}
                  >
                    <p className={`${pNormal} w-full !text-white opacity-80`}>
                      {item.label}
                    </p>
                  </div>
                  <div
                    className={`${cellPadding} ${middleListItemClassname} col-span-3 flex items-center gap-4`}
                  >
                    {item.icon}
                    <p className={`${pNormal} font-medium !text-white`}>
                      {item.text}
                    </p>
                  </div>

                  <div
                    className={`${cellPadding} ${middleListItemClassname} col-span-3 flex items-center`}
                  >
                    <div className="flex items-center gap-2 md:w-32">
                      <span className="text-2xl font-bold leading-10 text-white">
                        {item.value}
                      </span>
                      <div className="h-4 w-full bg-[#454547] md:w-32">
                        <div
                          className="h-4 bg-[#5C5CFF]"
                          style={{
                            width: `${parseFloat(item.value) * 100}%`,
                          }}
                        ></div>
                      </div>
                    </div>

                    <div>
                      <FiChevronRight className="h-8 w-8 !text-[#A3A3A3]" />
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>

      <section className="mx-5 block border-x border-t border-gray-400/40 p-4 pb-8 pt-0 text-white md:hidden">
        <div className={`flex flex-col py-6`}>
          <h4 className="text-xl font-medium leading-7 text-white md:text-[22px]">
            {categoryTitle}
            <br />
            {categorySubtitle})
          </h4>
          <a
            href="#"
            className="mt-5 flex items-center text-base font-semibold leading-6 text-white"
          >
            Learn more <FiChevronRight className="h-8 w-8 !text-white" />
          </a>
        </div>

        <div className="flex justify-between pb-2 pt-4">
          <div className={`text-[#FFFFFF99]`}>Category</div>
          <div className={`flex items-baseline gap-2 text-[#FFFFFF99]`}>
            Score
            <AppTooltip
              id="score-tooltip"
              content="Context Adherence is a measurement of closed-domain hallucinations: cases where your model said things that were not provided in the context.If a response is adherent to the context (i.e. it has a value of 1 or close to 1), it only contains information given in the context. If a response is not adherent (i.e. it has a value of 0 or close to 0), it's likely to contain facts not included in the context provided to the model."
            >
              <img
                className="cursor-pointer"
                src="/info-icon.svg"
                alt="Info icon"
              />
            </AppTooltip>
          </div>
        </div>
        {contextList.map((item) => {
          return (
            <div className="flex flex-col gap-4 border-t border-gray-400/40 py-4">
              <div className={`flex items-center justify-between gap-2`}>
                <p className={`${pNormal} w-full !text-white opacity-80`}>
                  {item.label}
                </p>
                <div className={`flex items-center gap-x-2`}>
                  <span className="text-2xl font-bold leading-10 text-white">
                    {item.value}
                  </span>
                  <div className="flex items-center">
                    <div className="h-4 w-20 bg-[#454547]">
                      <div
                        className="h-4 bg-[#5C5CFF]"
                        style={{
                          width: `100%`,
                        }}
                      ></div>
                    </div>
                    <Link to={item.link}>
                      <FiChevronRight className="h-8 w-8 !text-[#A3A3A3]" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className={`flex flex-col gap-4`}>
                {item.icon}
                <p className={`${pNormal} font-medium !text-white`}>
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default TopModelSection;
