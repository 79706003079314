import React from 'react';

import { h4Style, h2Style } from '@/components/hallucination-index-2024/common';
import {
  IHiModel,
  IScrModel,
  IMcrLcrModel,
} from '@/components/hallucination-index-2024/model-data-helpers';
import Dropdown from './dropdown';

const firstCellStyle = '!border-none !bg-white p-3 text-left font-bold';
const firstCellStyleWithBorderTop =
  '!border-b-0 !border-l-0 !border-r-0 p-2 font-bold text-left';

const datasetCellStyle = 'border !bg-white p-3 text-left';

const modelCellStyle = 'border p-3 bg-hi-2 text-left';
const modelCellTaskInsightStyle =
  'border p-3 bg-hi-2 text-xs w-[27%] text-left';

type Props = {
  hiModels: IHiModel[];
  scrModels: IScrModel[];
  mcrModels: IMcrLcrModel[];
  lcrModels: IMcrLcrModel[];
};

const ModelComparison = ({
  hiModels,
  scrModels,
  mcrModels,
  lcrModels,
}: Props) => {
  const [modelOne, setModelOne] = React.useState(hiModels[0]);
  const [modelTwo, setModelTwo] = React.useState(hiModels[1]);

  const dropdownItems = hiModels.map((model) => ({
    label: model.name,
    value: model.name,
  }));

  const onDropdownOneChange = (modelName: string) => {
    const model = hiModels.find((model) => model.name === modelName);
    if (model) {
      setModelOne(model);
    }
  };

  const onDropdownTwoChange = (modelName: string) => {
    const model = hiModels.find((model) => model.name === modelName);
    if (model) {
      setModelTwo(model);
    }
  };

  const comparePricePerToken = (
    model1: IHiModel['name'],
    model2: IHiModel['name'],
  ) => {
    const model1Price = hiModels.find(
      (model) => model.name === model1,
    )?.pricePerToken;
    const model2Price = hiModels.find(
      (model) => model.name === model2,
    )?.pricePerToken;

    if (model1Price && model2Price) {
      return `${(model2Price / model1Price).toFixed(2)}x`;
    }

    return 'NA';
  };

  const getShortContextRAGScore = (modelName: IHiModel['name']) => {
    return scrModels.find((model) => model.name === modelName)
      ?.contextAdherenceScore;
  };

  const getMediumContextRAGScore = (modelName: IHiModel['name']) => {
    return mcrModels.find((model) => model.name === modelName)
      ?.contextAdherenceScore;
  };

  const getLongContextRAGScore = (modelName: IHiModel['name']) => {
    return lcrModels.find((model) => model.name === modelName)
      ?.contextAdherenceScore;
  };

  return (
    <section className="mx-auto w-full max-w-[90rem] lg:px-[85px]">
      {/* head */}
      <div className="border border-b-0 border-black/20 p-4 lg:py-6">
        <h2 className={`${h2Style} !font-inter !font-bold`}>
          Model comparison
        </h2>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="">
              <th className="border p-3"></th>
              <th className="border p-3">Dataset</th>
              <th className="border bg-hi-3 p-2">
                <Dropdown
                  items={dropdownItems}
                  defaultSelected={0}
                  onChange={onDropdownOneChange}
                  value={dropdownItems.find(
                    (item) => item.value === modelOne.name,
                  )}
                />
              </th>
              <th className="border bg-hi-3 p-2">
                <Dropdown
                  items={dropdownItems}
                  defaultSelected={1}
                  onChange={onDropdownTwoChange}
                  value={dropdownItems.find(
                    (item) => item.value === modelTwo.name,
                  )}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Model Info */}
            <tr className="!border-l">
              <td className={firstCellStyle}>Model Info</td>
              <td className={datasetCellStyle}>Developer</td>
              <td className={modelCellStyle}>{modelOne.developer}</td>
              <td className={modelCellStyle}>{modelTwo.developer}</td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>License</td>
              <td className={modelCellStyle}>{modelOne.license}</td>
              <td className={modelCellStyle}>{modelTwo.license}</td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>Model Parameters</td>
              <td className={modelCellStyle}>{modelOne.modelParameters}</td>
              <td className={modelCellStyle}>{modelTwo.modelParameters}</td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>Supported context length</td>
              <td className={modelCellStyle}>{modelOne.contextLength}k</td>
              <td className={modelCellStyle}>{modelTwo.contextLength}k</td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>$/M prompt tokens</td>
              <td className={modelCellStyle}>{modelOne.pricePerToken}</td>
              <td className={modelCellStyle}>{modelTwo.pricePerToken}</td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>$/M response tokens</td>
              <td className={modelCellStyle}>{modelOne.responseCost}</td>
              <td className={modelCellStyle}>{modelTwo.responseCost}</td>
            </tr>

            {/* Cost Insight */}
            <tr className="!border-l">
              <td className={firstCellStyleWithBorderTop}>Cost insight</td>
              <td className={datasetCellStyle}>
                Prompt cost compared to Claude 3.5 Sonnet
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken(
                  'claude-3-5-sonnet-20240620',
                  modelOne.name,
                )}
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken(
                  'claude-3-5-sonnet-20240620',
                  modelTwo.name,
                )}
              </td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>
                Prompt cost compared to gpt-4o-2024-08-06
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken('gpt-4o-2024-08-06', modelOne.name)}
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken('gpt-4o-2024-08-06', modelTwo.name)}
              </td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>
                Prompt cost compared to Gemini 1.5 Pro
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken('gemini-1.5-pro-001', modelOne.name)}
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken('gemini-1.5-pro-001', modelTwo.name)}
              </td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>
                Prompt cost compared to Meta-Llama-3.1-70B-Instruct
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken(
                  'meta-llama-3.1-70b-instruct',
                  modelOne.name,
                )}
              </td>
              <td className={modelCellStyle}>
                {comparePricePerToken(
                  'meta-llama-3.1-70b-instruct',
                  modelTwo.name,
                )}
              </td>
            </tr>

            {/* Performance */}
            <tr className="!border-l">
              <td className={firstCellStyleWithBorderTop}>Performance</td>
              <td className={datasetCellStyle}>Short context RAG</td>
              <td className={modelCellStyle}>
                <div className="flex flex-row items-center gap-2">
                  {getShortContextRAGScore(modelOne.name)?.toFixed(2) || 'N/A'}
                  {getShortContextRAGScore(modelOne.name) && (
                    <div className="h-3 w-20 !bg-white">
                      <div
                        style={{
                          width: `${getShortContextRAGScore(modelOne.name) * 100}%`,
                        }}
                        className={`h-full bg-[#47C278]`}
                      />
                    </div>
                  )}
                </div>
              </td>
              <td className={modelCellStyle}>
                <div className="flex flex-row items-center gap-2">
                  {getShortContextRAGScore(modelTwo.name)?.toFixed(2) || 'N/A'}
                  {getShortContextRAGScore(modelTwo.name) && (
                    <div className="h-3 w-20 !bg-white">
                      <div
                        style={{
                          width: `${getShortContextRAGScore(modelTwo.name) * 100}%`,
                        }}
                        className={`h-full w-[${getShortContextRAGScore(modelTwo.name) * 100}%] bg-[#47C278]`}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
            <tr className="!border-l">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>Medium context RAG</td>
              <td className={modelCellStyle}>
                <div className="flex flex-row items-center gap-2">
                  {getMediumContextRAGScore(modelOne.name)?.toFixed(2) || 'N/A'}
                  {getMediumContextRAGScore(modelOne.name) && (
                    <div className="h-3 w-20 !bg-white">
                      <div
                        style={{
                          width: `${getMediumContextRAGScore(modelOne.name) * 100}%`,
                        }}
                        className={`h-full w-[${getMediumContextRAGScore(modelOne.name) * 100}%] bg-[#47C278]`}
                      />
                    </div>
                  )}
                </div>
              </td>
              <td className={modelCellStyle}>
                <div className="flex flex-row items-center gap-2">
                  {getMediumContextRAGScore(modelTwo.name)?.toFixed(2) || 'N/A'}
                  {getMediumContextRAGScore(modelTwo.name) && (
                    <div className="h-3 w-20 !bg-white">
                      <div
                        style={{
                          width: `${getMediumContextRAGScore(modelTwo.name) * 100}%`,
                        }}
                        className={`h-full w-[${getMediumContextRAGScore(modelTwo.name) * 100}%] bg-[#47C278]`}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
            <tr className="!border-l border-b">
              <td className={firstCellStyle}></td>
              <td className={datasetCellStyle}>Long context RAG</td>
              <td className={modelCellStyle}>
                <div className="flex flex-row items-center gap-2">
                  {getLongContextRAGScore(modelOne.name)?.toFixed(2) || 'N/A'}
                  {getLongContextRAGScore(modelOne.name) && (
                    <div className="h-3 w-20 !bg-white">
                      <div
                        style={{
                          width: `${getLongContextRAGScore(modelOne.name) * 100}%`,
                        }}
                        className={`h-full w-[${getLongContextRAGScore(modelOne.name) * 100}%] bg-[#47C278]`}
                      />
                    </div>
                  )}
                </div>
              </td>
              <td className={modelCellStyle}>
                <div className="flex flex-row items-center gap-2">
                  {getLongContextRAGScore(modelTwo.name)?.toFixed(2) || 'N/A'}
                  {getLongContextRAGScore(modelTwo.name) && (
                    <div className="h-3 w-20 !bg-white">
                      <div
                        style={{
                          width: `${getLongContextRAGScore(modelTwo.name) * 100}%`,
                        }}
                        className={`h-full w-[${getLongContextRAGScore(modelTwo.name) * 100}%] bg-[#47C278]`}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ModelComparison;
