import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { RagTaskExampleData } from '../rag-task-examples-data';
import Markdown from 'markdown-to-jsx';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  prompt: RagTaskExampleData['prompt'];
};

type Props = {
  data: RagTaskExampleData;
};

const Modal = ({ isOpen, closeModal, prompt }: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all sm:h-full sm:max-w-full md:max-w-4xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Prompt
                </Dialog.Title>
                <div className="mt-2 max-h-[35rem] overflow-y-auto">
                  <p className="text-sm text-gray-500">{prompt.context}</p>
                  <p className="my-3 text-sm text-gray-500">
                    Task: {prompt.task}
                  </p>
                  <p className="text-sm text-gray-500">
                    Question: {prompt.queston}
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="bg-hi-20 px-5 py-2 text-white hover:opacity-80"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const RagTaskExampleItem = ({ data }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const truncatedPrompt = data.prompt.shortContext;
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        prompt={data.prompt}
      />
      <div
        className="my-4 flex w-full flex-col gap-10 border-t border-t-black/20 py-6 first:border-none md:flex-row md:py-8"
        key={data.model}
      >
        <div className="flex flex-col gap-2 md:w-2/5">
          <div className="flex flex-row gap-2 border-b border-b-black/20 py-2">
            <p className="w-1/2 text-base font-medium text-black/60">Model</p>
            <p className="w-1/2 text-base font-medium text-black">
              {data.model}
            </p>
          </div>
          <div className="flex flex-row gap-2 border-b border-b-black/20 py-2">
            <p className="w-1/2 text-base font-medium text-black/60">
              Is response correct?
            </p>
            <p className="w-1/2 text-base font-medium text-black">
              {data.isResponseCorrect}
            </p>
          </div>
          <div className="flex flex-row gap-2 py-2">
            <p className="w-1/2 text-base font-medium text-black/60">
              Context Adherence
            </p>
            <p className="w-1/2 text-base font-medium text-black">
              {data.contextAdherence}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 md:w-3/5">
          <div className="col-[1_/_3] flex flex-col gap-4 md:flex-row md:gap-6">
            {/* prompt */}
            <div className="w-full rounded-2xl border border-black/20 md:w-1/2">
              <div className="w-full rounded-t-2xl bg-hi-2 p-5">prompt</div>
              <p className="p-5 text-base font-normal text-black">
                <Markdown>{truncatedPrompt}</Markdown>
              </p>
              <button
                onClick={() => setIsOpen(true)}
                className="m-5 bg-hi-20 px-5 py-2 text-white hover:opacity-80"
              >
                Show full
              </button>
            </div>
            {/* response */}
            <div className="flex w-full flex-col rounded-2xl border border-black/20 md:w-1/2">
              <div className="w-full rounded-t-2xl bg-hi-2 p-5">response</div>
              <p className="p-5 text-base font-normal text-black">
                {data.response}
              </p>
            </div>
          </div>
          <p className="pl-1 md:col-[2_/_3]">Answer: {data.answer}</p>
        </div>
      </div>
    </>
  );
};

export default RagTaskExampleItem;
