import React from 'react';

import { smoothScrollTo } from '../../../common/smooth-scroll';

const Navbar = ({ visibleSection, items }) => {
  return (
    <div className="order-first flex w-full flex-row items-stretch border">
      {items.map((item, index) => (
        <a
          href={`#${item.id}`}
          onClick={(e) => {
            e.preventDefault();
            smoothScrollTo(`${item.id}`, 180);
          }}
          className="block w-1/3"
        >
          <div
            key={item.id}
            className={`w-full overflow-hidden whitespace-nowrap border-r p-3 text-sm text-smoky-black last:border-none md:text-base ${
              visibleSection === item.id ? 'bg-hi-2' : 'bg-hi-3'
            }`}
          >
            {item.title}
          </div>
        </a>
      ))}
    </div>
  );
};

export default Navbar;
