import React from 'react';

const PricingInfo = () => {
  return (
    <p className="text-right text-sm">
      Pricing for open source models is based on{' '}
      <a
        className="text-hi-100 hover:underline"
        href="https://www.together.ai/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Together.ai
      </a>{' '}
      as of July 10
    </p>
  );
};

export default PricingInfo;
