import React from 'react';

import { h3Style, pNormal } from '@/components/hallucination-index-2024/common';
import Chart from '../common/chart';
import ResultSnapshot from '../common/result-snapshot';
import RagTaskExamples from '../common/rag-task-examples';

import AnthropicLogo from '@/assets/anthropic.svg';
import GoogleLogo from '@/assets/google.svg';

import { IScrModel } from '@/components/hallucination-index-2024/model-data-helpers';
import QwenLogo from '@/assets/qwen.svg';
import PricingInfo from '../common/pricing-info';
import { RagTaskExampleData } from '../rag-task-examples-data';

const MetaLogo = () => <img width={39} height={39} src="/creators/meta.png" />;

const resultSnapshotItems = [
  {
    category: 'Best closed source model',
    icon: <AnthropicLogo />,
    modelName: `claude-3-5-sonnet`,
    score: 0.97,
  },
  {
    category: 'Lagging closed-source model',
    icon: <AnthropicLogo />,
    modelName: `claude-3-haiku`,
    score: 0.92,
  },
  {
    category: 'Best open-source model',
    icon: <MetaLogo />,
    modelName: `llama-3.1-405b-instruct`,
    score: 0.97,
  },
  {
    category: 'Lagging open-source model',
    icon: <QwenLogo />,
    modelName: `qwen2-7b-instruct`,
    score: 0.75,
  },
  {
    category: 'Best performance for the cost',
    icon: <GoogleLogo />,
    modelName: `gemini-1.5-flash`,
    score: 0.94,
  },
];

type Props = {
  scrModels: IScrModel[];
  ragTaskExamples: RagTaskExampleData[];
};

const SCR = ({ scrModels, ragTaskExamples }: Props) => {
  return (
    <section className="my-12 lg:mb-24 lg:mt-12">
      {/* Head */}
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="md:w-1/2">
          <h3 className={h3Style}>Short Context RAG (SCR)</h3>
          <p className="my-3 text-lg leading-8 !text-black lg:text-[22px]">
            Less than 5k tokens
          </p>
        </div>

        <p className={`${pNormal} !text-[#454547] md:w-1/2`}>
          The Short Context RAG seeks to identify the most efficient model for
          understanding contexts up to 5k tokens. Its primary goal is to detect
          any loss of information or reasoning capability within these contexts.
          Similar to referencing select pages in a book, this method is
          especially suitable for tasks that demand domain-specific knowledge.
        </p>
      </div>

      {/* Chart */}
      <div className="my-16">
        <Chart
          maxYTickOffset={0.08}
          minYTickOffset={-0.05}
          models={scrModels}
          enabledFilters={[
            'type',
            'contextLength',
            'pricePerToken',
            'responseCost',
          ]}
        />
        <PricingInfo />
      </div>
      {/* Result Snapshot */}
      <ResultSnapshot items={resultSnapshotItems} />
      {/* RAG Task Examples */}
      <RagTaskExamples items={ragTaskExamples} />
    </section>
  );
};

export default SCR;
