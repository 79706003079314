import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const filterFields = [
  {
    name: 'type',
    label: 'Type',
    items: [
      { label: 'Open source', value: 'open source' },
      { label: 'Closed source', value: 'closed source' },
    ],
  },
  {
    name: 'contextLength',
    label: 'Context Length',
    items: [
      { label: 'SCR (up to 5k)', value: { from: 0, to: 5 } },
      { label: 'MCR (5k - 25k)', value: { from: 5, to: 25 } },
      { label: 'LCR (>25k)', value: { from: 25, to: Infinity } },
    ],
  },
  {
    name: 'pricePerToken',
    label: 'Prompt Cost',
    items: [
      { label: '<$1', value: { from: 0, to: 1 } },
      { label: '$1 - $3', value: { from: 1, to: 3 } },
      { label: '> $3', value: { from: 3, to: Infinity } },
    ],
  },
  {
    name: 'responseCost',
    label: 'Response Cost',
    items: [
      { label: '<$1', value: { from: 0, to: 1 } },
      { label: '$1 - $3', value: { from: 1, to: 3 } },
      { label: '> $3', value: { from: 3, to: Infinity } },
    ],
  },
];

export type Filter = {
  name: string;
  values: (string | { from: number; to: number })[];
};

type Props = {
  applyFilters: (filters: Filter[]) => void;
  enabledFilters: string[];
  defaultSelectedChartFilters: Filter[];
};

const ChartFilters = ({
  applyFilters,
  enabledFilters,
  defaultSelectedChartFilters,
}: Props) => {
  const filterContainerRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [filters, setFilters] = React.useState<Filter[]>(
    defaultSelectedChartFilters || [],
  );

  const filterOptions = filterFields.filter((f) =>
    enabledFilters.includes(f.name),
  );

  const handleSelect = (
    groupName: string,
    value: string | { from: number; to: number },
  ) => {
    const newFilters = [...filters];
    const groupIndex = newFilters.findIndex((f) => f.name === groupName);
    if (groupIndex === -1) {
      newFilters.push({ name: groupName, values: [value] });
    } else {
      const group = newFilters[groupIndex];
      const valueIndex = group.values.findIndex((v) => v === value);
      if (valueIndex === -1) {
        // promptType should only have one value
        if (group.name === 'promptType') {
          group.values = [value];
        } else {
          group.values.push(value);
        }
      } else {
        group.values.splice(valueIndex, 1);
        if (group.values.length === 0) {
          newFilters.splice(groupIndex, 1);
        }
      }
    }

    applyFilters(newFilters);
    setFilters(newFilters);
  };

  // set default filters
  // React.useEffect(() => {
  //   if (filters.length) {
  //     setTimeout(() => {
  //       applyFilters(filters);
  //     }, 200);
  //   }
  // }, []);

  const handleClickOutside = (event) => {
    if (
      filterContainerRef.current &&
      !filterContainerRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={filterContainerRef} className="relative ml-12 w-full max-w-72">
      <div
        className={`flex w-full flex-row items-center justify-between gap-3 border border-black/20 px-4 py-2`}
        onClick={() => setOpen(!open)}
      >
        <span className="text-lg">Filters</span>
        {open ? (
          <FiChevronUp className="h-7 w-7" />
        ) : (
          <FiChevronDown className="h-7 w-7" />
        )}
      </div>

      {/* list of filters */}
      {open && (
        <div className="absolute z-20 w-full border border-gray-300 bg-white">
          <div className="mb-2 flex gap-6 px-4 pb-2 pt-4">
            <button
              className="text-primary-600"
              onClick={() => {
                setFilters([]);
                applyFilters([]);
              }}
            >
              Clear
            </button>
          </div>

          {filterOptions.map((group) => (
            <div
              key={group.name}
              className="border-b border-b-black/20 px-4 py-4"
            >
              <p className="mb-2 font-semibold">{group.label}</p>
              {group.items.map((item) => (
                <label
                  key={item.label}
                  className="ml-4 flex cursor-pointer items-center space-x-2"
                >
                  <input
                    type="checkbox"
                    disabled={item.disabled}
                    checked={filters.some(
                      (filter) =>
                        filter.name === group.name &&
                        filter.values.some((v) => {
                          if (
                            typeof v === 'object' &&
                            v !== null &&
                            typeof item.value === 'object' &&
                            item.value !== null
                          ) {
                            // Assuming both v and item.value are objects with from and to properties
                            return (
                              v.from === item.value.from &&
                              v.to === item.value.to
                            );
                          } else {
                            // Direct comparison if v is not an object (e.g., a string)
                            return v === item.value;
                          }
                        }),
                    )}
                    onChange={() => handleSelect(group.name, item.value)}
                    className="form-checkbox text-primary-600"
                  />
                  <span>{item.label}</span>
                </label>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartFilters;
