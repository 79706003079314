import React from 'react';
import WavyBackground from '@/components/hallucination-index-2024/wave-backgroundHeader';
import { smoothScrollTo } from '../../common/smooth-scroll';

const WavyBackgroundHeader = ({ className }) => {
  const totalItems = 207;
  const itemsPerRow = 23;
  const rows = Math.ceil(totalItems / itemsPerRow);

  const shouldBeTransparent = (index) => {
    return (
      (index >= 69 && index <= 87) ||
      (index >= 92 && index <= 110) ||
      (index >= 115 && index <= 133) ||
      (index >= 138 && index <= 156) ||
      (index >= 161 && index <= 179)
    );
  };

  const hasLeftBorder = (index) => {
    return [69, 92, 115, 138, 161].includes(index);
  };

  const customWaveFunction = (
    ctx,
    w,
    h,
    nt,
    noise,
    waveColors,
    zoomFactor,
    getSpeed,
    waveWidth,
  ) => {
    nt += getSpeed();
    const scaledWidth = w * zoomFactor;
    const offsetX = (scaledWidth - w) / 2;

    for (let i = 0; i < 2; i++) {
      ctx.beginPath();
      ctx.lineWidth = waveWidth || 150;
      ctx.strokeStyle = waveColors[i % waveColors.length];
      for (let x = -offsetX; x < scaledWidth - offsetX; x += 4) {
        const y = noise(x / 2000, 0.3 * i, nt) * 100;
        ctx.lineTo(x, y + h * (0.2 + i * 0.15));
      }
      ctx.stroke();
      ctx.closePath();
    }
  };

  return (
    <WavyBackground
      speed="slow"
      className={`inset-0 w-full p-0 ${className}`}
      blur={55}
      waveOpacity={1.0}
      waveWidth={125}
      animationOffsetTop={0.2}
    >
      <div
        className="grid grid-rows-9"
        style={{ gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)` }}
      >
        {Array.from({ length: totalItems }).map((_, index) => (
          <div
            key={index}
            className={`h-14 bg-transparent ${
              shouldBeTransparent(index)
                ? 'border-0'
                : 'border border-gray-400/40'
            } ${hasLeftBorder(index) ? 'border-l border-l-gray-400/40' : ''}`}
          />
        ))}
      </div>
    </WavyBackground>
  );
};

const HiHero = () => {
  return (
    <>
      <section className="w-full bg-[#070707] pt-20">
        <div className="mx-auto max-w-[90rem] lg:px-[85px]">
          <div className="relative w-full">
            <div className="absolute left-0 top-0 z-10 flex h-full flex-col items-start justify-end px-[14px] pb-[82px] lg:px-3 lg:pb-[82px]">
              <h3 className="text-[28px] font-bold leading-[normal] text-white md:text-[4vw] 2xl:text-5xl">
                LLM Hallucination Index
              </h3>
              <h1 className="font-inter text-[42px] font-bold text-white md:text-[10vw] md:leading-[160px] 2xl:text-[152px]">
                RAG SPECIAL
              </h1>

              <p className="flex items-center gap-2 font-inter text-white lg:pl-2">
                Brought to you by
                <img
                  alt="Galileo Logo"
                  src={`/logo.svg`}
                  className="w-[78px]"
                />
              </p>
            </div>
            <WavyBackgroundHeader className="w-full" />
          </div>
          <div className="border-x border-gray-400/40 p-4 lg:px-4 lg:py-8">
            <p className="text-[18px] leading-loose text-white sm:text-[20px] lg:text-[22px]">
              A Ranking & Evaluation Framework For LLM Hallucinations
            </p>
            <a
              onClick={() => {
                smoothScrollTo('report');
              }}
              className="mt-8 inline-flex min-w-[260px] cursor-pointer justify-center bg-[#0000FF] p-5 text-base font-semibold leading-6 text-white no-underline"
            >
              Get The Full Report
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default HiHero;
